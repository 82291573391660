import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import Menu from "../components/menu";
import { Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Banner_KV from "../components/banner_KV";
import LuckyDrawPolicy from "../components/lucky_draw_policy";
import { User_info, Lucky_draw_redeem } from "../functions/lucky_draw";
import Carousel from "react-bootstrap/Carousel";
const { confirm } = Modal;

const LuckyDrawExchange = ({ history }) => {
  const addressStatus = localStorage.getItem("addressStatus");
  const petStatus = localStorage.getItem("petStatus");
  const profireStatus = localStorage.getItem("member_status");
  const [token, setToken] = useState("");
  // const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MywibWVtYmVyX2lkIjoxLCJjcmVhdGVkX2F0IjoiMjAyNC0wMy0yOCAxNzowOToyMCJ9.Y9O8oN0khCWBqCYQ0nZzkXO6MOwrW4V3kD24SG2gwUw";
  const [point, setPoint] = useState("");
  const [ticket, setTicket] = useState(0);
  const [current_point, setCurrentPoint] = useState(0);
  const last_point = Math.floor(current_point / 2) * 2;
  const minimumValue = 1;
  const [maximumValue, setmaximumValue] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    checkPermission2Exchange();
    // setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // window.location.href = "home";
    // return;
    if (addressStatus === "0" || petStatus === "0" || profireStatus === "0") {
      // let type = petStatus === "0" ? "pet" : "address";
      showPromiseConfirm();
    }

    const { user } = JSON.parse(window.localStorage.getItem("auth"));
    setToken(user.token);
    loadUserInfo(user.token);
  }, []);

  const loadUserInfo = async (authToken) => {
    User_info(authToken).then((res) => {
      // console.log("res.data", res.data.data);
      if (res.data) {
        var initial_point = res.data.data.points;
        setPoint(initial_point);
        // var initial_point = 35
        // setPoint(initial_point)
        setCurrentPoint(initial_point);
        var credit = Math.floor(initial_point / 2);
        setTicket(credit);
        setmaximumValue(credit);
      }
    });
  };

  const showPromiseConfirm = () => {
    let msgTitle = " ";
    let msgDesc = " ";
    let path = " ";

    // console.log("profireStatus",profireStatus)
    // console.log("addressStatus",addressStatus)
    // console.log("petStatus",petStatus)

    if (profireStatus === "0") {
      msgTitle = "กรุณาเพิ่มข้อมูลส่วนตัวให้ครบถ้วน";
      msgDesc = "กรุณาเพิ่มข้อมูลให้ครบถ้วน";
      path = "/edit_profile";
    } else if (addressStatus === "0") {
      msgTitle = "เพื่อรับสิทธิประโยชน์จากทางแบรนด์ตลอดทั้งปี";
      msgDesc = "กรุณาเพิ่มข้อมูลให้ครบถ้วน";
      path = "/add_address";
    } else if (petStatus === "0") {
      msgTitle = "กรุณาเพิ่ม ID Card ของสัตว์เลี้ยงให้ครบถ้วน";
      msgDesc = "กรุณาเพิ่ม ID Card ของสัตว์เลี้ยงให้ครบถ้วน";
      path = "/register_pets";
    }

    confirm({
      title: msgTitle,
      icon: <InfoCircleOutlined />,
      content: msgDesc,
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          history.push(path);
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  const checkPermission2Exchange = () => {
    // console.log("last_point", last_point)
    // if (addressStatus === "1" && petStatus === "1" && profireStatus === "1") {
    //    Lucky_draw_redeem(token, { point: last_point })
    // .then((res) => {
    //     const page = "lucky-draw-exchange";
    //     window.location.href = `alertSuscess/${page}`;
    //   })
    //   .catch((err) => {
    //     console.log("err: ", err);
    //     alert("บันทึกไม่สำเร็จ");
    //   });
    // } else {
    //   showPromiseConfirm();
    // }
    Lucky_draw_redeem(token, { point: last_point })
      .then((res) => {
        const page = "lucky-draw-exchange";
        window.location.href = `alertSuscess/${page}`;
      })
      .catch((err) => {
        console.log("err: ", err);
        alert("บันทึกไม่สำเร็จ");
      })
      .finally(() => {
        setIsModalOpen(false);
      });
  };

  // ------------------- Function for increment and decrement -------------------
  // Function to handle decrementing the count
  // const handleDecrement = () => {
  //     if (ticket > 1) {
  //         setCurrentPoint(current_point - 2);
  //         setTicket(ticket - 1);
  //         console.log("current_point", Math.floor(current_point))

  //     }
  // };

  // // Function to handle incrementing the count
  // const handleIncrement = () => {
  //     if (ticket > 0 && current_point < point) {
  //         setCurrentPoint(current_point + 2);
  //         setTicket(ticket - 1);
  //         console.log("current_point", Math.floor(current_point))

  //     }
  // };
  // ------------------- Function for increment and decrement -------------------

  const handleInputChange = (event) => {
    let newValue = event.target.value.trim(); // Remove leading and trailing spaces
    if (newValue === "") {
      // If input is empty, set the value to the minimum
      newValue = minimumValue;
    } else {
      newValue = parseInt(newValue);
      if (isNaN(newValue) || newValue === 0) {
        newValue = minimumValue;
      } else if (newValue >= maximumValue) {
        newValue = maximumValue;
      }
    }
    setTicket(newValue);
    setCurrentPoint(newValue * 2);
  };

  const handleInputBlur = () => {
    if (ticket === "") {
      setTicket(minimumValue);
      setCurrentPoint(minimumValue * 2);
    }
  };

  return (
    <div>
      <div className="backgroundLuckyDrawDetail">
        <br></br>
        <center>
          <div className="d-flex align-items-center home_cam">
            <div
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
              className="clover_icon"
            ></div>
            <b
              style={{
                fontSize: "2.5em",
              }}
              className="Header_LuckyDraw"
            >
              แลกแต้มลุ้นรางวัล
            </b>
            <div
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
              className="clover_icon"
            ></div>
          </div>
        </center>
        <br></br>

        <Form>
          <Carousel
            className="box_ฺBanner"
            activeIndex={0}
            onSelect={() => {
              console.log("onSelect");
            }}
          >
            <Carousel.Item>
              <img
                className="d-block w-100 borderradius20px"
                src="assets/luckyDraw/MooChie_Lucky-draw-2-9-7-2024.png"
                alt="p1.ll"
              />
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
          </Carousel>

          <div className="lucky_current_point">
            <div style={{ color: "#ff3a89", textAlign: "center" }}>
              คะแนนของฉัน
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/8893f97892e08b36b33b80d486605821e51076038917fa9baa1edaed4eccae92?apiKey=e95df7cd88704225a9c4058d8e5f379d&"
                style={{ marginRight: "15px" }}
              />
              <span style={{ marginRight: "15px", transform: "scale(1.5)" }}>
                {point}{" "}
              </span>
              <span>คะแนน</span>
            </div>
          </div>

          <div className="lucky_current_draw">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: "white",
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                กรุณากรอกจำนวนสิทธิ์ที่ต้องการลุ้น
              </div>
            </div>
            <div
              style={{
                color: "white",
                textAlign: "center",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              (2 คะแนน = 1 สิทธิ์)
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  color: "white",
                  textAlign: "center",
                  marginRight: "30px",
                }}
              >
                จำนวน
              </div>
              <input
                type="number"
                value={ticket}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                max={maximumValue}
                min={minimumValue}
                style={{
                  transform: "scale(1.5)",
                  justifyContent: "center",
                  borderRadius: "60px",
                  backgroundColor: "var(--Yellow, #fed231)",
                  display: "flex",
                  width: "50%",
                  color: "var(--Text, #212121)",
                  fontWeight: "600",
                  textAlign: "center",
                  padding: "8px",
                  border: "none",
                  maxWidth: "80px",
                }}
              />
              {/* <div className="lucky_current_draw_exchange">
                                <a style={{ marginRight: '30px', height: "20px" }} onClick={handleDecrement}>
                                    <img src="./assets/minus_button.png" style={{ height: "20px" }} />
                                </a>
                                <span style={{ transform: 'scale(1.5)' }}>{Math.floor(current_point/2)} </span>
                                <a style={{ marginLeft: '30px', height: "20px" }} onClick={handleIncrement}>
                                    <img src="./assets/plus_button.png" style={{ height: "20px" }} />
                                </a>
                            </div> */}
              <div
                style={{
                  color: "white",
                  textAlign: "center",
                  marginLeft: "30px",
                }}
              >
                สิทธิ์
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
          <div>
            <center>
              <Col>
                <Button
                  className="confirm_exchange_lucky_draw"
                  onClick={showModal}
                >
                  <b className="font_ButtonUpload">ใช้คะแนนแลกสิทธิ์</b>
                </Button>
              </Col>
            </center>
          </div>
          <br></br>
          <div
            className="mb-3 resgisterFoodter rules"
            controlId="formBasicCheckbox"
          >
            <b>
              <h4 style={{ color: "#FF0099" }}>
                <Button
                  style={{ color: "#FF0099" }}
                  className="icon_3"
                  variant="light"
                >
                  <b className="font_ButtonUpload"> </b>{" "}
                </Button>
                &nbsp;&nbsp;กติกาและเงื่อนไข
              </h4>
            </b>

            <LuckyDrawPolicy />
          </div>
          <br></br>
        </Form>
        <br></br>
      </div>

      <Menu />
      <Modal
        title="ยืนยันการแลกคะแนน"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          คุณแน่ใจหรือไม่ว่าต้องการแลกคะแนน {current_point} คะแนน เพื่อรับสิทธิ์
        </p>
      </Modal>
    </div>
  );
};

export default LuckyDrawExchange;
