import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import { register, getLogin, lineTokenVerify } from "../functions/member";
import { userID_ } from "../functions/userAddress";
import { useSelector, useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Typography } from "antd";
const { Text } = Typography;

const initialState = {
  firstname: "",
  surname: "",
  access_token: "",
  gender: "female",
  tel: "",
  // birthdate: "",
  email: "",
  username: "",
};

const Register = ({ history }) => {
  const liff = window.liff;
  const auth = JSON.parse(window.localStorage.getItem("auth"));
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialState);
  const { firstname, surname, access_token, gender, tel, email, username } =
    values;

  const dispatch = useDispatch();
  const maxDate = new Date().toISOString().split("T")[0];

  useEffect(() => {
    const liffIdProd = sessionStorage.getItem("liffIdProd");

    liff
      .init({ liffId: liffIdProd })
      .then(async () => {
        if (!liff.isLoggedIn()) {
          liff.login();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [liff]);

  useEffect(() => {
    if (auth && auth.user) history.push("/home");

    const accessToken = localStorage.getItem("lineAccessToken");
    setValues({ ...values, ["access_token"]: accessToken });
  }, [auth, history]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(values);
    register(values)
      .then((res) => {
        // getTokenVerify();
        loginWithLine();
      })
      .catch((err) => {
        console.log("err: ", err);
        if (err.response) {
          // const res = err.response.data
          console.log("err.response: ", err.response);
          let { message, status_code } = err.response.data;
          if (status_code === "400") {
            alert(message);
          } else {
            alert("something went wrong?");
          }
        }
        setLoading(false);
      });
  };

  const clearData = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("addressStatus");
    localStorage.removeItem("petStatus");
    localStorage.removeItem("member_status");
    localStorage.removeItem("lineUserPic");
    localStorage.removeItem("lineUserId");

    dispatch({
      type: "LOGOUT",
      payload: {},
    });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handlerLogin = (line_id, access_token) => {
    const lineProfileProd = localStorage.getItem("lineProfileProd");
    const lineProfile = localStorage.getItem("lineProfile");
    const lineProfile2 = localStorage.getItem("lineProfile2");
    // string to object
    const _lineProfile = JSON.parse(lineProfile);
    const _lineProfile2 = JSON.parse(lineProfile2);
    const _lineProfile3 = JSON.parse(lineProfileProd);

    const data = {
      line_id: _lineProfile.userId,
      line_id_2: _lineProfile2.userId,
      line_id_3: _lineProfile3.userId,
    };

    getLogin({ line_id: line_id, data, access_token: access_token })
      .then((res) => {
        let response = res.data;

        localStorage.setItem("accessToken", response.data.session);
        localStorage.setItem("addressStatus", response.address_status);
        localStorage.setItem("petStatus", response.pet_status);
        localStorage.setItem("member_status", response.member_status);
        localStorage.setItem("accepted_campaign", response.accepted_campaign);

        loadProfile(response.data.session);
      })
      .catch((err) => {
        console.log(err);
        clearData();
      });
  };

  const loginWithLine = () => {
    const lineToken = localStorage.getItem("lineToken");
    const lineTokenProd = localStorage.getItem("lineTokenProd");
    const lineProfileProd = localStorage.getItem("lineProfileProd");
    const lineProfile1 = localStorage.getItem("lineProfile");
    const lineProfile2 = localStorage.getItem("lineProfile2");
    // string to object
    const lineProfile = JSON.parse(lineProfileProd);

    // string to object
    const _lineProfile = JSON.parse(lineProfile1);
    const _lineProfile2 = JSON.parse(lineProfile2);
    const _lineProfile3 = JSON.parse(lineProfileProd);

    const data = {
      line_id: _lineProfile.userId,
      line_id_2: _lineProfile2.userId,
      line_id_3: _lineProfile3.userId,
    };

    getLogin(lineToken, data, lineTokenProd)
      .then((res) => {
        let response = res.data;

        localStorage.setItem("accessToken", response.data.session);
        localStorage.setItem("addressStatus", response.address_status);
        localStorage.setItem("petStatus", response.pet_status);
        localStorage.setItem("member_status", response.member_status);
        localStorage.setItem("accepted_campaign", response.accepted_campaign);

        loadProfile(response.data.session);
      })
      .catch((err) => {
        console.log("🚀 ~ useEffect ~ err:", err);
        clearData();
      });
  };

  const loadProfile = (token) => {
    userID_(token)
      .then((res) => {
        let resProfile = res.data.data;
        // dispatch({
        //   type: "LOGGED_IN_USER",
        //   payload: {
        //     firstname: resProfile.firstname,
        //     surename: resProfile.surename,
        //     gender: resProfile.gender,
        //     tel: resProfile.tel,
        //     birthdate: resProfile.birthdate,
        //     email: resProfile.email,
        //     img: resProfile.img,
        //     username:resProfile.username,
        //     token: token,
        //   },
        // });

        const data = {
          user: {
            firstname: resProfile.firstname,
            surename: resProfile.surename,
            gender: resProfile.gender,
            tel: resProfile.tel,
            birthdate: resProfile.birthdate,
            email: resProfile.email,
            img: resProfile.img,
            token: token,
          },
          tokens: token,
        };

        window.localStorage.setItem("auth", JSON.stringify(data));

        window.location.href = "/home";
      })
      .catch((err) => {
        console.log(err);
        clearData();
      });
  };

  const getTokenVerify = () => {
    lineTokenVerify(access_token)
      .then((res) => {
        let response = res.data;

        handlerLogin(response.userId, access_token);
      })
      .catch((err) => {
        console.log(err);
        clearData();
      });
  };

  return (
    <div className=" backgroundMain">
      <center>
        <div className=" d-flex align-items-center register_cam">
          <div
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
            className="dog_icon"
          ></div>
          <b className="Header_register">&nbsp;&nbsp;ลงทะเบียน&nbsp;&nbsp;</b>
          <div
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
            className="cat_icon"
          ></div>
        </div>
      </center>
      <Form onSubmit={handleSubmit}>
        {/* <Form.Group className="mb-3 ">
          <Form.Label>ชื่อ</Form.Label>
          <Form.Control
            name="firstname"
            placeholder="ชื่อ"
            value={firstname}
            onChange={handleChange}
            required
          />
        </Form.Group> */}

        {/* <Form.Group className="mb-3">
          <Form.Label>สกุล</Form.Label>
          <Form.Control
            name="surname"
            placeholder="นามสกุล"
            value={surname}
            onChange={handleChange}
            required
          />
        </Form.Group> */}

        <Form.Group className="mb-3">
          <Form.Label>Username</Form.Label>
          <Form.Control
            name="username"
            placeholder="username"
            value={username}
            onChange={handleChange}
            required
          />
        </Form.Group>

        {/* <div className="center">
          <table>
            <tbody>
              <tr>
                <td>
                  <input
                    type="radio"
                    name="gender"
                    value="female"
                    onChange={handleChange}        
                  />
                  &nbsp;&nbsp;ผู้หญิง
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="radio"
                    name="gender"
                    value="male"
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;ผู้ชาย
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="radio"
                    name="gender"
                    value="LGBTQ+"
                    onChange={handleChange}
                  />
                  &nbsp;&nbsp;LGBTQ+
                </td>
              </tr>
            </tbody>
          </table>
        </div> */}

        <br></br>

        <Form.Group className="mb-3">
          <Form.Label>หมายเลขโทรศัพท์</Form.Label>
          <Form.Control
            name="tel"
            placeholder="หมายเลขโทรศัพท์"
            type="tel"
            value={tel}
            onChange={handleChange}
            required
          />
        </Form.Group>

        {/* <Form.Label>วันเดือนปีเกิด</Form.Label>
        <input
          type="date"
          style={{width:'100%'}}
          name="birthdate"
          className="form-control"
          placeholder="วันเดือนปี"
          value={birthdate}
          onChange={handleChange}
          max={maxDate}
          required
        ></input> */}

        <br></br>

        {/* <Form.Group className="mb-3">
          <Form.Label>e-mail</Form.Label>
          <Form.Control
            name="email"
            placeholder="moochie@email.com"
            type="email"
            value={email}
            onChange={handleChange}
            required
          />
        </Form.Group> */}

        <div className="mb-3 resgisterFoodter padding20px">
          <Form.Label>ข้อตกลงและเงื่อนไข</Form.Label>
          <br></br>
          <table>
            <tbody>
              <tr>
                <td valign="top">
                  <input
                    type="checkbox"
                    name="myTextEditBox"
                    value="checked"
                    required
                  />
                </td>
                <td className="displaychecklistDetail">
                  ขอต้อนรับเข้าสู่ Moochie Give Me More ชอปมากกว่า ให้มากกว่า
                  ก่อนเริ่มใช้งานเว็บไซต์{" "}
                  <Text underline>https://moochierewards.com</Text>{" "}
                  โปรดอ่านเงื่อนไขด้านล่างอย่างละเอียด เว็บไซต์นี้จัดทำโดย
                  บริษัท ไทยออซัม จำกัด(มหาชน) (ซึ่งต่อไปนี้จะเรียกว่า
                  "เว็บไซต์")
                  เราตระหนักถึงความสำคัญของข้อมูลส่วนบุคคลที่คุณมอบให้แก่เรา
                  และเชื่อว่าเรามีหน้าที่รับผิดชอบในการจัดการ ปกป้อง
                  และดำเนินการข้อมูลส่วนบุคคลของคุณอย่างเหมาะสม
                  ข้อตกลงและเงื่อนไขทั้งหมดนี้ได้รับการออกแบบมาเพื่อช่วยให้คุณเข้าใจเกี่ยวกับวิธีที่เราเก็บรวบรวม
                  ใช้ เปิดเผย และ/หรือดำเนินการข้อมูลส่วนบุคคลที่คุณมอบให้แก่เรา
                  และ/หรือข้อมูลของคุณที่เราครอบครองไม่ว่าจะในปัจจุบันหรืออนาคต
                  รวมทั้งยังเป็นข้อมูลประกอบการตัดสินใจก่อนที่คุณจะให้ข้อมูลส่วนตัวใดๆ
                  แก่เรา โปรดอ่านข้อตกลงและเงื่อนไขโดยละเอียด
                  หากคุณมีข้อสงสัยเกี่ยวกับข้อมูลเหล่านี้ โปรดติดต่อเรา
                  <br></br>
                  <br></br>
                  <tr style={{ marginLeft: "5%" }}>
                    <td style={{ verticalAlign: "text-top" }}> 1. &nbsp;</td>
                    <td>
                      {" "}
                      ไทยออซัม ขอสงวนสิทธิ์ในการแก้ไข ดัดแปลง เปลี่ยนแปลง
                      หรือยกเลิกข้อตกลงและเงื่อนไขใดๆ
                      โดยมิได้แจ้งให้ทราบล่วงหน้า
                    </td>
                  </tr>
                  <tr style={{ marginLeft: "5%" }}>
                    <td style={{ verticalAlign: "text-top" }}> 2. &nbsp;</td>
                    <td>
                      {" "}
                      คุณสามารถใช้บริการ รวมถึงการเข้าร่วมกิจกรรมทางการตลาดของ
                      รับข่าวสารและส่วนลดแคมเปญต่างๆ มากมายได้
                      โดยลงทะเบียนสมัครเป็นสมาชิก
                      และจำเป็นต้องให้ข้อมูลที่ถูกต้อง
                      สมบูรณ์และตรงกับความเท็จจริง ณ ปัจจุบันมากที่สุด
                      หากมีการเปลี่ยนแปลงข้อมูลใด ๆ
                      คุณตกลงที่จะเปลี่ยนแปลงข้อมูลนั้นๆ ให้ถูกต้องอย่างสม่ำเสมอ
                      สำหรับลูกค้าที่เป็นผู้เยาว์ตามกฎหมายแห่งราชอาณาจักรไทย
                      จะต้องได้รับความยินยอมจากผู้แทนโดยธรรมของคุณ
                      ถึงจะเข้าใช้งานได้
                    </td>
                  </tr>
                  <tr style={{ marginLeft: "5%" }}>
                    <td style={{ verticalAlign: "text-top" }}> 3. &nbsp;</td>
                    <td>
                      {" "}
                      ในการใช้บริการ การลงทะเบียนบัญชีกับเรา เยี่ยมชมเว็บไซต์
                      และ/หรือ เข้าถึงบริการ
                      คุณได้รับทราบและตกลงว่าคุณยอมรับข้อปฏิบัติ ข้อกำหนด
                      และ/หรือนโยบายที่กำหนดไว้ในข้อตกลงและเงื่อนไขนี้
                      และคุณยินยอมให้เราเก็บรวบรวม ใช้ เปิดเผย
                      และ/หรือดำเนินการข้อมูลส่วนบุคคลของคุณ
                    </td>
                  </tr>
                  <tr style={{ marginLeft: "5%" }}>
                    <td style={{ verticalAlign: "text-top" }}> 4. &nbsp;</td>
                    <td>
                      {" "}
                      หากคุณมีเจตนาในการใช้เว็ปไซต์เพื่อคุกตามหรือก่อให้เกิดความเสียหายใดๆ
                      ทั้งต่อบริษัท ไทยออซัม จำกัด(มหาชน) หรือ แบรนด์ มูชี่
                      หรือฝ่ายที่เกี่ยวข้องในกรณีใดๆ ก็ตาม
                      คุณยินยอมที่จะรับผิดชอบค่าเสียหายที่เกี่ยวข้องทั้งทางตรงและทางอ้อม
                      ทางเราไม่รับผิดชอบความเสียหายใดๆ จากการกระทำต่อบุคคลอื่นๆ
                    </td>
                  </tr>
                  <tr style={{ marginLeft: "5%" }}>
                    <td style={{ verticalAlign: "text-top" }}> 5. &nbsp;</td>
                    <td>
                      {" "}
                      บริษัท ไทยออซัม จำกัด(มหาชน)
                      ขอสงวนสิทธิในการระงับสถานะสมาชิกโดยไม่แจ้งล่วงหน้า
                      หากตรวจสอบบัญชีของคุณและพบว่าให้ข้อมูลที่เป็นเท็จ
                      หรือไม่ได้ให้ข้อมูลที่ล่าสุด
                      หรือไม่ได้เก็บข้อมูลผู้ใช้และรหัสผ่านของคุณเป็นความลับ
                    </td>
                  </tr>
                  <tr style={{ marginLeft: "5%" }}>
                    <td style={{ verticalAlign: "text-top" }}> 6. &nbsp;</td>
                    <td>
                      {" "}
                      เราจะเก็บรวบรวมข้อมูลส่วนบุคคลของคุณเมื่อ..
                      <tr style={{ marginLeft: "5%" }}>
                        <tr style={{ marginLeft: "5%" }}>
                          <td style={{ verticalAlign: "text-top" }}>
                            {" "}
                            - &nbsp;
                          </td>
                          <td>
                            {" "}
                            เมื่อคุณได้เข้าถึงและ/หรือใช้บริการหรือเว็บไซต์ของเรา
                            หรือลงทะเบียน และ/หรือสมัครบัญชีผู้ใช้กับเรา
                          </td>
                        </tr>
                        <tr style={{ marginLeft: "5%" }}>
                          <td style={{ verticalAlign: "text-top" }}>
                            {" "}
                            - &nbsp;
                          </td>
                          <td>
                            {" "}
                            เมื่อคุณเข้าเว็บไซต์หรือกดรับสิทธิ์ต่างๆ
                            เช่นคูปองส่วนลด ผ่าน LINE OFFICIAL MOOCHIE:
                            https://lin.ee/eszT5MR
                          </td>
                        </tr>
                        <tr style={{ marginLeft: "5%" }}>
                          <td style={{ verticalAlign: "text-top" }}>
                            {" "}
                            - &nbsp;
                          </td>
                          <td>
                            {" "}
                            เมื่อคุณส่งแบบฟอร์มการสมัครหรือแบบฟอร์มอื่นๆ
                            ที่เกี่ยวข้องกับผลิตภัณฑ์และบริการของเรา
                            ไม่ว่าจะแบบออนไลน์หรือแบบฟอร์มเป็นเอกสาร
                          </td>
                        </tr>
                        <tr style={{ marginLeft: "5%" }}>
                          <td style={{ verticalAlign: "text-top" }}>
                            {" "}
                            - &nbsp;
                          </td>
                          <td>
                            {" "}
                            เมื่อคุณทำข้อตกลงใดๆ
                            หรือให้เอกสารหรือข้อมูลอื่นใดที่เกี่ยวข้องกับการติดต่อระหว่างคุณกับเรา
                            หรือเมื่อคุณใช้ผลิตภัณฑ์และบริการของเรา
                          </td>
                        </tr>
                        <tr style={{ marginLeft: "5%" }}>
                          <td style={{ verticalAlign: "text-top" }}>
                            {" "}
                            - &nbsp;
                          </td>
                          <td>
                            {" "}
                            เมื่อคุณติดต่อกับเรา เช่น ผ่านทางโทรศัพท์
                            (ซึ่งจะได้รับการบันทึก) จดหมาย แฟกซ์
                            การประชุมแบบเห็นหน้ากัน แพลตฟอร์มสื่อทางสังคม
                            และอีเมล
                          </td>
                        </tr>
                        <tr style={{ marginLeft: "5%" }}>
                          <td style={{ verticalAlign: "text-top" }}>
                            {" "}
                            - &nbsp;
                          </td>
                          <td>
                            {" "}
                            เมื่อคุณใช้บริการทางอิเล็กทรอนิกส์
                            หรือติดต่อกับเราผ่านเว็บไซต์หรือใช้บริการ
                            ซึ่งรวมถึงแต่ไม่จำกัดเพียง การใช้ผ่านคุกกี้
                            ซึ่งเราอาจปรับใช้เมื่อคุณใช้บริการหรือเข้าถึงเว็บไซต์
                          </td>
                        </tr>
                        <tr style={{ marginLeft: "5%", whiteSpace: "nowrap" }}>
                          <td> - &nbsp;</td>
                          <td>เมื่อคุณดำเนินธุรกรรมผ่านบริการของเรา </td>
                        </tr>
                        <tr style={{ marginLeft: "5%" }}>
                          <td style={{ verticalAlign: "text-top" }}>
                            {" "}
                            - &nbsp;
                          </td>
                          <td style={{ verticalAlign: "text-top" }}>
                            เมื่อคุณให้ความคิดเห็นหรือคำร้องเรียนแก่เรา
                          </td>
                        </tr>
                        <tr style={{ marginLeft: "5%" }}>
                          <td style={{ verticalAlign: "text-top" }}>
                            {" "}
                            - &nbsp;
                          </td>
                          <td>
                            {" "}
                            เมื่อคุณใช้หรือร้องขอจะใช้บริการที่จัดไว้ให้โดยผู้ให้บริการภายนอกของเราบนไซต์
                            เช่น การชำระเงิน บริการด้านโลจิสติกส์ เป็นต้น
                          </td>
                        </tr>
                        <tr style={{ marginLeft: "5%" }}>
                          <td style={{ verticalAlign: "text-top" }}>
                            {" "}
                            - &nbsp;
                          </td>
                          <td style={{ verticalAlign: "text-top" }}>
                            เมื่อคุณเข้าเยี่ยมชมหรือใช้เว็บไซต์ของบริษัทฯ
                          </td>
                        </tr>
                        <tr style={{ marginLeft: "5%" }}>
                          <td style={{ verticalAlign: "text-top" }}>
                            {" "}
                            - &nbsp;
                          </td>
                          <td>
                            {" "}
                            เมื่อคุณส่งข้อมูลส่วนบุคคลของคุณให้แก่เราด้วยเหตุผลใดก็ตาม
                          </td>
                        </tr>
                      </tr>
                    </td>
                  </tr>
                  <tr style={{ marginLeft: "5%" }}>
                    <td style={{ verticalAlign: "text-top" }}> 7. &nbsp;</td>
                    <td>
                      {" "}
                      ข้อมูลส่วนบุคคลที่บริษัท ไทยออซัม จำกัด(มหาชน)
                      อาจจะเก็บรวบรวม ได้แก่ ชื่อ เพศ ที่อยู่อีเมล วันเกิด
                      ที่อยู่ตามบัตรประชาชนหรือพาสปอร์ต
                      ที่อยู่สำหรับเรียกเก็บเงิน ที่อยู่สำหรับการจัดส่ง
                      รายละเอียดบัญชีธนาคารและข้อมูลการชำระเงิน เบอร์โทรศัพท์
                      หมายเลขบัตรประชาชนหรือหมายเลขพาสปอร์ต
                      ที่อยู่อินเทอร์เน็ตโปรโตคอล (IP)
                      ข้ออมูลอื่นใดเกี่ยวกับผู้ใช้เมื่อผู้ใช้ลงทะเบียนเข้าใช้เว็บไซต์ของเรา
                      รวมถึงข้อมูลที่เกี่ยวกับวิธีการใช้บริการหรือเว็บไซต์ของผู้ใช้
                      รวมถึงข้อมูลส่วนบุคคลที่คุณอาจให้แก่เราผ่านทางเว็บไซต์
                    </td>
                  </tr>
                  <tr style={{ marginLeft: "5%" }}>
                    <td style={{ verticalAlign: "text-top" }}> 8. &nbsp;</td>
                    <td>
                      {" "}
                      เราจะนำข้อมูลของคุณไปใช้เพื่อ....
                      <tr style={{ marginLeft: "5%" }}>
                        <tr style={{ marginLeft: "5%" }}>
                          <td style={{ verticalAlign: "text-top" }}>
                            {" "}
                            - &nbsp;
                          </td>
                          <td>
                            {" "}
                            เพื่อเข้าถึงบริการและเว็บไซต์ของเรา
                            รวมถึงความสัมพันธ์และบัญชีผู้ใช้ของคุณกับเรา
                          </td>
                        </tr>
                        <tr style={{ marginLeft: "5%" }}>
                          <td style={{ verticalAlign: "text-top" }}>
                            {" "}
                            - &nbsp;
                          </td>
                          <td>
                            {" "}
                            จดจำความต้องการของคุณเพื่อจัดหาบริการแก่คุณ
                            รวมถึงช่วยอำนวยความสะดวกในการจัดเตรียมบริการของเรา
                          </td>
                        </tr>
                        <tr style={{ marginLeft: "5%" }}>
                          <td style={{ verticalAlign: "text-top" }}>
                            {" "}
                            - &nbsp;
                          </td>
                          <td>
                            {" "}
                            เพื่อตอบรับ ดำเนินการ จัดการ
                            หรือทำธุรกรรมให้เสร็จสมบูรณ์
                            และ/หรือทำตามการร้องขอเกี่ยวกับสินค้าและบริการของคุณ
                            และแจ้งให้คุณทราบเกี่ยวกับปัญหาด้านการบริการและการดำเนินการเกี่ยวกับบัญชี
                            ที่มีความผิดปกติ
                          </td>
                        </tr>
                        <tr style={{ marginLeft: "5%" }}>
                          <td style={{ verticalAlign: "text-top" }}>
                            {" "}
                            - &nbsp;
                          </td>
                          <td>
                            {" "}
                            เพื่อบังคับใช้เงื่อนไขการให้บริการหรือข้อตกลงสิทธิ์การใช้งานสำหรับผู้ใช้ที่เกี่ยวข้อง
                          </td>
                        </tr>
                        <tr style={{ marginLeft: "5%" }}>
                          <td style={{ verticalAlign: "text-top" }}>
                            {" "}
                            - &nbsp;
                          </td>
                          <td>
                            {" "}
                            เพื่อระบุตัวตนและ/หรือการตรวจสอบความถูกต้อง
                            รวมถึงป้องกันความปลอดภัยและสิทธิ์ ทรัพย์สินส่วนบุคคล
                            หรือความปลอดภัยของบุคคลอื่น
                          </td>
                        </tr>
                        <tr style={{ marginLeft: "5%" }}>
                          <td style={{ verticalAlign: "text-top" }}>
                            {" "}
                            - &nbsp;
                          </td>
                          <td>
                            {" "}
                            เพื่อจัดการหรืออำนวยความสะดวกในการให้บริการลูกค้า
                            ปฏิบัติตามคำสั่งของคุณ
                            จัดการหรือตอบข้อซักถามที่ได้รับจากคุณ
                          </td>
                        </tr>
                        <tr style={{ marginLeft: "5%" }}>
                          <td style={{ verticalAlign: "text-top" }}>
                            {" "}
                            - &nbsp;
                          </td>
                          <td>
                            เพื่อติดต่อหรือสื่อสารกับคุณผ่านการโทร ข้อความ
                            และ/หรือ อีเมล และ/หรือการส่งไปรษณีย์
                            หรือในรูปแบบอื่นใด
                            เพื่อวัตถุประสงค์ในการบริหารและ/หรือจัดการความสัมพันธ์ระหว่างคุณกับเราหรือการใช้บริการของเรา
                          </td>
                        </tr>
                        <tr style={{ marginLeft: "5%" }}>
                          <td style={{ verticalAlign: "text-top" }}>
                            {" "}
                            - &nbsp;
                          </td>
                          <td>
                            เพื่อดำเนินการศึกษาวิจัย วิเคราะห์
                            และพัฒนากิจกรรมต่างๆ เพื่อวิเคราะห์การใช้บริการ
                            เพื่อนำไปปรับปรุงบริการหรือสินค้าของเรา
                            และ/หรือช่วยเสริมประสบการณ์การซื้อสินค้าของลูกค้า
                          </td>
                        </tr>
                        <tr style={{ marginLeft: "5%" }}>
                          <td style={{ verticalAlign: "text-top" }}>
                            {" "}
                            - &nbsp;
                          </td>
                          <td>
                            เพื่อให้สามารถตรวจสอบและทำการสำรวจและอื่นๆ
                            เพื่อตรวจสอบขนาดและองค์ประกอบของกลุ่มเป้าหมายของเรา
                            และทำความเข้าใจเกี่ยวกับประสบการณ์ที่ลูกค้าได้รับจากบริการของเรา
                          </td>
                        </tr>
                        <tr style={{ marginLeft: "5%" }}>
                          <td style={{ verticalAlign: "text-top" }}>
                            {" "}
                            - &nbsp;
                          </td>
                          <td>
                            เนื่องจากวัตถุประสงค์ที่เราจะเก็บรวบรวม ใช้ เปิดเผย
                            และ/หรือดำเนินการข้อมูลส่วนบุคคลของคุณขึ้นอยู่กับสถานการณ์เฉพาะหน้า
                            วัตถุประสงค์ดังกล่าวอาจไม่ปรากฎในข้างต้น
                            อย่างไรก็ตาม
                            เราจะแจ้งให้คุณทราบถึงวัตถุประสงค์ดังกล่าว ณ
                            เวลาที่ขอรับความยินยอมจากคุณ
                            เว้นแต่การดำเนินการข้อมูลที่เกี่ยวข้องโดยไม่ได้รับความยินยอมจากคุณนั้นเป็นไปตามที่กฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลกำหนดไว้
                          </td>
                        </tr>
                      </tr>
                    </td>
                  </tr>
                  <tr style={{ marginLeft: "5%" }}>
                    <td style={{ verticalAlign: "text-top" }}> 9. &nbsp;</td>
                    <td>
                      {" "}
                      หากคุณไม่ประสงค์ให้เรา เก็บรวบรวม
                      ข้อมูล/ข้อมูลส่วนบุคคลดังที่กล่าวมาข้างต้น
                      คุณอาจยกเลิกได้ทุกเมื่อผ่านทางไซต์หรือโดยการแจ้งความประสงค์ให้เจ้าหน้าที่คุ้มครองข้อมูลของเราทราบเป็นลายลักษณ์อักษร
                      อย่างไรก็ตาม
                      การยกเลิกการเก็บรวบรวมข้อมูลส่วนบุคคลหรือถอนความยินยอมในการเก็บรวบรวม
                      ใช้ แบ่งปัน หรือดำเนินการข้อมูลส่วนบุคคลของคุณกับเรานั้น
                      อาจส่งผลต่อการใช้บริการของคุณ เช่น การส่งข่าวสารสิทธิพิเศษ
                      และโปรโมชั่นต่างๆ
                    </td>
                  </tr>
                  <tr style={{ marginLeft: "5%" }}>
                    <td style={{ verticalAlign: "text-top" }}> 10. &nbsp;</td>
                    <td>
                      เมื่อคุณใช้บัตรเครดิตเพื่อทำธุรกรรมบนเว็บไซต์
                      คุณกำลังให้ข้อมูลของคุณแก่ผู้ให้บริการภายนอก
                      ในการเชื่อมต่อนั้นข้อมูลที่คุณได้ให้แก่ผู้ให้บริการภายนอกจะอยู่ภายใต้นโยบายความเป็นส่วนตัวที่เกี่ยวข้องของผู้ให้บริการภายนอกนั้น
                      ทาง ไทยออซัม ไม่อาจเข้าถึงข้อมูลเหล่านั้น
                      และไม่ได้เก็บรวบรวมรายละเอียดบัตรเครดิตของคุณ
                    </td>
                  </tr>
                  <tr style={{ marginLeft: "5%" }}>
                    <td style={{ verticalAlign: "text-top" }}> 11. &nbsp;</td>
                    <td>
                      การเข้าถึงเว็บไซต์ด้วยอุปกรณ์ของคุณ
                      อุปกรณ์ของคุณจะส่งข้อมูลซึ่งอาจมีข้อมูลส่วนบุคคลของคุณซึ่งจะถูกบันทึกไว้โดยเซิร์ฟเวอร์ของเว็บเมื่อคุณเรียกดูเว็บของเรา
                      ซึ่งโดยทั่วไปจะรวมถึงแต่ไม่จำกัดเพียง ที่อยู่ IP
                      ของคอมพิวเตอร์ ระบบปฏิบัติการ ชื่อ/เวอร์ชันของเบราว์เซอร์
                      เว็บเพจที่อ้าง หน้าที่ร้องขอ วันที่/เวลา
                      และบางครั้งอาจรวมถึง "คุกกี้"
                      (ซึ่งคุณสามารถปิดใช้งานได้โดยกำหนดลักษณะอุปกรณ์ของคุณ)
                      เพื่อช่วยให้เว็บไซต์จดจำการเข้าชมครั้งล่าสุดของคุณ
                      เมื่อคุณเข้าสู่ระบบ
                      ข้อมูลนี้จะเชื่อมโยงกับบัญชีผู้ใช้ของคุณ
                      นอกจากนี้ข้อมูลนี้จะนำไปใช้ในเชิงสถิติที่ไม่ระบุชื่อเพื่อช่วยให้เราทำความเข้าใจเกี่ยวกับวิธีที่ผู้เยี่ยมชมใช้งานเว็บไซต์ของเรา
                    </td>
                  </tr>
                  <tr style={{ marginLeft: "5%" }}>
                    <td style={{ verticalAlign: "text-top" }}> 12. &nbsp;</td>
                    <td>
                      เราให้การสนับสนุนด้านการบริการลูกค้าผ่านอีเมล SMS
                      และแบบฟอร์มความคิดเห็น เพื่อให้การสนับสนุนลูกค้า
                      เราจะขอที่อยู่อีเมลและหมายเลขติดต่อของคุณ
                      เราใช้เพียงข้อมูลที่ได้รับจากลูกค้าที่ขอรับการสนับสนุน
                      รวมถึงแต่ไม่จำกัดเพียง ที่อยู่อีเมล
                      สำหรับการบริการสนับสนุนลูกค้า
                      และเราจะไม่ส่งหรือแบ่งปันข้อมูลนี้กับบุคคลภายนอก
                    </td>
                  </tr>
                  <tr style={{ marginLeft: "5%" }}>
                    <td style={{ verticalAlign: "text-top" }}> 13. &nbsp;</td>
                    <td>
                      เราอาจร้องขอข้อมูลจากผู้ใช้ผ่านการสำรวจต่างๆ
                      การเข้าร่วมในการสำรวจเหล่านี้ขึ้นอยู่กับความสมัครใจทั้งสิ้น
                      ดังนั้นคุณจึงสามารถเลือกได้ว่าจะเปิดเผยข้อมูลของคุณแก่เราหรือไม่
                      ข้อมูลที่ร้องขออาจรวมถึงแต่ไม่จำกัดเพียง ข้อมูลการติดต่อ
                      (ได้แก่ ที่อยู่อีเมล) และข้อมูลทางประชากรศาสตร์ (ได้แก่
                      ความสนใจ หรือช่วงอายุ)
                      ข้อมูลที่ได้จากการสำรวจนี้จะนำมาใช้เพื่อตรวจสอบและปรับปรุงการใช้งานและความพึงพอใจในการบริการ
                      และจะไม่ถูกส่งให้แก่บุคคลภายนอก ยกเว้น
                      ผู้รับจ้างที่ช่วยเราในการบริหารจัดการและดำเนินการสำรวจ
                    </td>
                  </tr>
                  <tr style={{ marginLeft: "5%" }}>
                    <td style={{ verticalAlign: "text-top" }}> 14. &nbsp;</td>
                    <td>
                      หากคุณมีคำถามหรือข้อกังวลเกี่ยวกับข้อปฏิบัติว่าด้วยความเป็นส่วนตัวของเรา
                      หรือเกี่ยวกับรายละเอียดของสินค้า ราคาหรือ
                      ขั้นตอนในการชำระเงินและการจัดส่ง โปรดติดต่อ
                      marcom@thaiawesome.com หรือโทร 02-8215545
                      วันจันทร์-วันศุกร์ เวลา 8.00-17.00 น.{" "}
                    </td>
                  </tr>
                </td>
              </tr>
            </tbody>
          </table>
          <br></br>

          <center>
            <Form.Group>
              <Button
                variant="primary"
                style={{ backgroundColor: "#FF0099", borderColor: "#FF0099" }}
                type="submit"
              >
                {!loading ? (
                  <>ลงทะเบียน</>
                ) : (
                  <LoadingOutlined
                    style={{
                      fontSize: 24,
                    }}
                    spin
                  />
                )}
              </Button>
            </Form.Group>
          </center>
        </div>
      </Form>
    </div>
  );
};
export default Register;
