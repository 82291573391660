import axios from "axios";

export const register = async (member) =>
  await axios.post(`${process.env.REACT_APP_API}/register`, member);

// export const getLogin = async (member) =>{
//   return await axios.post(`${process.env.REACT_APP_API}/login`, member);
// }

export const getProfile = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/profile`, {
    headers: {
      session: authtoken,
    },
  });
};

export const lineTokenVerify = async (accessToken) => {
  return await axios.post(`${process.env.REACT_APP_API}/auth-line`, { access_token: accessToken }, {
  });
};

export const getLogin = async (access_token, data, access_token2) => {
  console.log("🚀 ~ getLogin ~ data:", data)

  
  return await axios.post(`${process.env.REACT_APP_API}/login`, data, {
    headers: {
      'Authorization': access_token,
      'Authorization-line': access_token2,
    }
  });
}



