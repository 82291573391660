import axios from "axios";

export const getRedeem = async (authtoken) => {
    return await axios.get(`${process.env.REACT_APP_API}/get-product`, {
        headers: {
            session: authtoken,
          },
      
    });
  };

  export const addSendRedeem = async (authtoken, sendRedeem) => {
    return await axios.post(`${process.env.REACT_APP_API}/redeem`, sendRedeem, {
      headers: {
        session: authtoken,
      },
    });
  };
