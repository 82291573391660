import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  getProvince,
  getDistricts,
  getSubdistricts,
  getZipcode,
} from "../functions/getProvince";
import { addAddress } from "../functions/userAddress";
import { useSelector } from "react-redux";

const Add_addressRedeem = () => {
  const { user } = JSON.parse(window.localStorage.getItem("auth"))
  const token = user.token;

  useEffect(() => {
    loadProvince();
    // loadDistricts()
  }, []);

  const [province_id, setProvince] = useState(0); //สร้างตัวแปรไว้ส่งค่าจังหวัดที่เลือก
  const [districts, setDistricts] = useState([]); // รับค่าอำเภอทั้งหมด
  const [provinces, setProvinces] = useState([]); // รับค่าจังหวัดทั้งหมด
  const [district_id, setDistrict] = useState(0); //สร้างตัวแปรไว้ส่งค่าจังหวัดที่เลือก
  const [subDistricts, setSubDistricts] = useState([]);
  const [subDistrict_id, setSubDistrict] = useState(0);
  const [Zipcode, setZipcode] = useState(0);
  const [region_id, setRegion_id] = useState(0);
  const [address, setAddress] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    let authtoken = token;
    let addAddress_ = {
      address: address,
      province_id: province_id,
      district_id: district_id,
      subdistrict_id: subDistrict_id,
      region_id: region_id,
    };

    addAddress(authtoken, addAddress_)
      .then((res) => {
        const page = "add_addressRedeem";
        window.location.href = `alertSuscess/${page}`;
      })
      .catch((err) => {
        console.log("err: ", err);
        alert("บันทึกไม่สำเร็จ");
        // window.location.reload(false);alertSuscess
      });
  };

  const loadProvince = async () => {
    getProvince(token).then((res) => {
      setProvinces(res.data.data);
    });
  };

  const handleProvince = (e) => {
    setProvince(e.target.value);
    loadDistricts(e.target.value);
    loadSubDistricts(e.target.value);
    setZipcode("");

    const filtered = provinces.filter((region_id) => {
      return region_id.id === Number(e.target.value);
    });

    setRegion_id(filtered[0].region_id);
  };

  //เรียกตำบล
  const loadDistricts = async (province_id) => {
    getDistricts(token, province_id).then((res) => {
      setDistricts(res.data.data);
 
    });
  };

  //ฟังชั่นเลือกอำเภอ
  const handleDistrict = (e) => {
    setDistrict(e.target.value);
    loadSubDistricts(e.target.value);
    setZipcode("");
  };

  //เรียกตำบล
  const loadSubDistricts = async (district_id) => {
    getSubdistricts(token, district_id).then((res) => {
      setSubDistricts(res.data.data);
    
    });
  };

  //ฟังชั่นเลือกตำบล
  const handleSubDistrict = (e) => {

    setSubDistrict(e.target.value);
    loadZipcode(e.target.value);
  };

  //เรียกไปรษณีย์
  const loadZipcode = async (subDistrict_id) => {
    getZipcode(token, subDistrict_id).then((res) => {
      setZipcode(res.data.data[0].zipcode);
  
    });
  };

  return (
    <div className="backgroundMain">
      <br></br>
      <center className="d-flex align-items-center">
        <Button
          className="botton_back"
          style={{ backgroundColor: "#FFF", borderColor: "#FFF" }}
          variant="light"
          href="/delivery"
        >
          <b className="font_ButtonUpload"> </b>{" "}
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b className="Header_regis_pets "> เพิ่มที่อยู่ใหม่</b>
      </center>
      <br></br>

      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3 ">
          <Form.Label>ที่อยู่</Form.Label>
          <Form.Control
            placeholder="บ้านเลขที่/อาคาร/ถนน/หมู่บ้าน"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Form.Group>
        <Form.Select
          style={{ backgroundColor: "#F5F5F5" }}
          aria-label="Default select example"
          onChange={handleProvince}
          value={province_id}
        >
          <option>จังหวัด</option>
          {provinces.map((item) => (
            <option value={item.id}>{item.name_th}</option>
          ))}
        </Form.Select>
        <br></br>
        <Form.Select
          style={{ backgroundColor: "#F5F5F5" }}
          aria-label="Default select example"
          onChange={handleDistrict}
          value={district_id}
        >
          <option>เขต/อำเภอ</option>
          {districts.map((item) => (
            <option value={item.id}>{item.name_th}</option>
          ))}
        </Form.Select>
        <br></br>
        <Form.Select
          Districts
          style={{ backgroundColor: "#F5F5F5" }}
          aria-label="Default select example"
          value={subDistrict_id}
          onChange={handleSubDistrict}
        >
          <option>แขวง/ตำบล</option>
          {subDistricts.map((item) => (
            <option value={item.id}>{item.name_th}</option>
          ))}
        </Form.Select>
        <br></br>
        <Form.Group className="mb-3 ">
          <Form.Control
            placeholder="รหัสไปรษณีย์"
            value={Zipcode}
            onChange={(e) => setZipcode(e.target.value)}
          />
        </Form.Group>

        <br></br>
        <center>
          <Form.Group controlId="formBasicCheckbox">
            <Button
              style={{
                backgroundColor: "#FF0099",
                borderColor: "#FF0099",
                fontSize: "13px",
                color: "#FFF",
              }}
              variant="primary"
              type="submit"
              // href="edit_profile"
            >
              บันทึก
            </Button>
          </Form.Group>
        </center>
      </Form>
    </div>
  );
};

export default Add_addressRedeem;
