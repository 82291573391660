import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import Card from "react-bootstrap/Card";
import ControlledCarousel from "../components/slide.js";
import Menu from "../components/menu";
import { profile } from "../functions/profile.js";
import { useSelector } from "react-redux";
import Banner_user from "../components/banner_user";

const Profile = () => {
  // const { user } = useSelector((state) => ({ ...state }));
  const { user } = JSON.parse(window.localStorage.getItem("auth"))
  const token = user.token;
  const lineToken = localStorage.getItem("lineAccessToken");

  let BimageUser =
    "https://res.cloudinary.com/dysfeos3s/image/upload/v1679385881/uat-moochie/edit_profile_petprofile_iodkjp.png";

  // const token = process.env.REACT_APP_TOKEN;
  const [profile_, setProfile] = useState([]);
  const [nameUser, setNameUser] = useState("");
  const [surnameUser, setSurnameUser] = useState("");
  const [totolPointUser, setTotolPointUser] = useState(0);
  const [priceUser, setPriceUser] = useState(0);
  const [imageUser, setImg] = useState(BimageUser);

  const [rank, setRank] = useState("");
  const [tier, setTier] = useState("");
  const [point, setPoint] = useState("");
  const [paddingRuningCss, setPaddingRuningCss] = useState([]);
  const [telUser, setTelUser] = useState("");

  const [rzz, setRzz] = useState("");

  const [slidepets, setSlidepets] = useState([]);
  const [logRedeem, setLogRedeem] = useState([]);

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = async () => {
    profile(token, lineToken).then((res) => {
      setProfile(res.data.data);

      setNameUser(res.data.data.firstname);
      setSurnameUser(res.data.data.surname);
      setTotolPointUser(res.data.data.total_point);
      setPriceUser(res.data.data.price);
      setTelUser(res.data.data.tel);
      setLogRedeem(res.data.data.log_redeem);
      setImg(res.data.data.img);
      setSlidepets(res.data.data.pet);

      let RuningPoint = 0;
      let totolPointUser = res.data.data.total_point;

      if (totolPointUser === 0) {
        RuningPoint = 1;
      } else if (
        (totolPointUser >= 1 && totolPointUser <= 10) ||
        (totolPointUser >= 51 && totolPointUser <= 70) ||
        (totolPointUser >= 151 && totolPointUser <= 180)
      ) {
        RuningPoint = 1;
        //  console.log("222")
      } else if (
        (totolPointUser >= 11 && totolPointUser <= 20) ||
        (totolPointUser >= 71 && totolPointUser <= 90) ||
        (totolPointUser >= 181 && totolPointUser <= 210)
      ) {
        RuningPoint = 2;
        //  console.log("333")
      } else if (
        (totolPointUser >= 21 && totolPointUser <= 30) ||
        (totolPointUser >= 91 && totolPointUser <= 110) ||
        (totolPointUser >= 211 && totolPointUser <= 240)
      ) {
        RuningPoint = 3;
        //  console.log("444")
      } else if (
        (totolPointUser >= 31 && totolPointUser <= 48) ||
        (totolPointUser >= 111 && totolPointUser <= 139) ||
        (totolPointUser >= 241 && totolPointUser <= 270)
      ) {
        RuningPoint = 4;
        //  console.log("555")
      } else if (
        (totolPointUser >= 48 && totolPointUser <= 50) ||
        (totolPointUser >= 140 && totolPointUser <= 150) ||
        (totolPointUser >= 270 && totolPointUser <= 300)
      ) {
        RuningPoint = 5;
        // console.log("666")
      } else if (totolPointUser > 300) {
        RuningPoint = 6;
        //  console.log("8888")
      }

      setRank(`/assets/tier/rank1_3/ranking-${res.data.data.tier_rank}.png`);
      setTier(`/assets/tier/member/${res.data.data.tier}-member.png`);
      setPoint(
        `/assets/tier/${res.data.data.tier}/${RuningPoint}-${res.data.data.tier}.png`
      );

      setRzz(RuningPoint);

      if (RuningPoint === 1 || RuningPoint === 0) {
        setPaddingRuningCss({
          marginTop: "11%",
          paddingLeft: "4%",
        });
      } else if (RuningPoint === 2 && res.data.data.tier !== "platinum") {
        setPaddingRuningCss({
          marginTop: "11%",
          paddingLeft: "22%",
        });
      } else if (RuningPoint === 3 && res.data.data.tier !== "platinum") {
        setPaddingRuningCss({
          marginTop: "11%",
          paddingLeft: "37%",
        });
      } else if (RuningPoint === 4 && res.data.data.tier !== "platinum") {
        setPaddingRuningCss({
          marginTop: "11%",
          paddingLeft: "52%",
        });
      } else if (RuningPoint === 5 && res.data.data.tier !== "platinum") {
        setPaddingRuningCss({
          marginTop: "11%",
          paddingLeft: "71%",
        });
      } else if (RuningPoint === 6 || res.data.data.tier === "platinum") {
        setPaddingRuningCss({
          marginTop: "11%",
          paddingLeft: "59%",
        });
      }
    });
  };

  return (
    <div>
      <div className="backgroundProfile">
        <center>
          <b className="HeaderRedeem_rewards">PROFILE</b>
        </center>

        <div className="box1_profile">
          <div className="Sub1_box1_profile">
            <div
              className="image_profile"
              style={{ backgroundImage: `url(${rank})` }}
            >
              <div className="detial_profile">
                <Button
                  style={{
                    backgroundSize: "95%",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "transparent",
                    borderColor: "transparent",
                    backgroundImage: "url(assets/profile_button1.png)",
                    color: "#FF0099",
                    fontSize: "11px",
                    float: "right",
                    height: "65px",
                    marginTop: "3%",
                  }}
                  variant="primary"
                  type="submit"
                  href="edit_profile"
                >
                  <div style={{ paddingTop: "26px", height: "45px" }}>
                    <b>แก้ไข Profile</b>
                  </div>{" "}
                </Button>
              </div>
              <div className="detial_profile2">
                <x className="cumulative1">ยอดซื้อสะสม</x>
                <br></br>
                <x className="cumulativeMoney">
                  {" "}
                  {priceUser.toLocaleString("en-US")}{" "}
                </x>
                &nbsp; <x className="cumulative1">บาท</x>
              </div>

              <center>
                <div
                  className="frame_profile"
                  style={{ backgroundImage: `url(${imageUser})` }}
                ></div>
              </center>
            </div>
          </div>
          <div className="Myname_profile">
            <b className="Myname_Name_profile">
              {nameUser} {surnameUser}
            </b>
          </div>
          <div
            className="ranking_profile"
            style={{ backgroundImage: `url(${tier})` }}
          ></div>
          <div
            className="ranking_profile"
            style={{ backgroundImage: `url("")` }}
          ></div>
        </div>
        <br></br>

        <center>
          <x className="MyPoint">คะแนนสะสมจากยอดซื้อ</x>
        </center>

        <div
          className="d-flex align-items-center bg_sumpoint"
          style={{ backgroundImage: `url(${point})` }}
        >
          <div style={paddingRuningCss}>
            <b style={{ fontSize: "12px", color: "#ed1e79" }}>
              {totolPointUser.toLocaleString("en-US")}
            </b>
            &nbsp;
            <b style={{ fontSize: "12px", color: "#ed1e79" }}>คะแนน</b>
          </div>
        </div>
        <br></br>
        <b className="profile_pets">PROFILE สัตว์เลี้ยง</b>
        <br></br>
        <br></br>

        <ControlledCarousel
          nameUser={nameUser}
          surnameUser={surnameUser}
          slidepets={slidepets}
          telUser={telUser}
        />

        <br></br>

        <div className="boxRecord">
          <b>ประวัติ</b>
        </div>

        <Card
          style={{
            backgroundColor: "#ed6d9d",
            borderColor: "#fff",
            paddingTop: "10px",
          }}
        >
          <center>
            <div style={{ width: "90%" }}>
              <Card.Title className="HeadteadePoint">
                <x>การแลกคะแนน</x>
              </Card.Title>
            </div>
          </center>
          <Card.Body style={{ paddingTop: "0px" }}>
            <Card.Text className="teadePointDetail">

              {logRedeem.map((item) => (
                <div>
                  แลก {item.point} คะแนน{" "}
                  <x className="pointfont2">{item.product}</x>
                </div>
              ))}
              {/* <x>แลก xxx คะแนน</x> <x className="pointfont2">ชื่อของรางวัล</x><br></br> */}
            </Card.Text>
          </Card.Body>
        </Card>

        <Button
          style={{
            backgroundColor: "#FFF",
            borderColor: "#FFF",
            borderRadius: "10px",
            marginTop: "10px",
          }}
          href="Upload_receipt"
        >
          <b className="bottonProfile">สะสมคะแนน</b>
        </Button>
        <Button
          style={{
            backgroundColor: "#FFF",
            borderColor: "#FFF",
            borderRadius: "10px",
            float: "right",
            marginTop: "10px",
          }}
          href="redeem_rewards"
        >
          <b className="bottonProfile">ไปที่หน้าแลกคะแนน {">"}</b>
        </Button>
        <br></br>
        <br></br>
        <Banner_user />
        <div style={{ height: "100px" }}></div>
      </div>
      <Menu />
    </div>
  );
};

export default Profile;
