import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

function Banner_user() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel className='box_ฺBanner' activeIndex={index} onSelect={handleSelect}>
   
      <Carousel.Item>
        <img
          className="d-block w-100 borderradius20px"
          src="assets/banner/member/p1.png"
          alt='p1.png'
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100 borderradius20px"
          src="assets/banner/member/p2.png"
          alt='p2.png'
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100 borderradius20px"
          src="assets/banner/member/p3.png"
          alt='p3.png'
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100 borderradius20px"
          src="assets/banner/member/p4.png"
          alt='p4.png'
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100 borderradius20px"
          src="assets/banner/member/p5.png"
          alt='p5.png'
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>

      
     

    </Carousel>
    
  );
}

export default Banner_user;