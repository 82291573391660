import axios from "axios";

export const profile = async (authtoken,lineToken) => {
  return await axios.get(`${process.env.REACT_APP_API}/profile`, {
    headers: {
      session: authtoken,
      Authorization:lineToken,
    },
    
  });
};

export const myProfileRank = async (authtoken,lineToken) => {
  return await axios.get(`${process.env.REACT_APP_API}/get-rank`, {
    headers: {
      session: authtoken,
      // Authorization:lineToken,
    },
  });
};
  