import React from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingToRedirect from "./LoadingToRedirect";
import { useDispatch } from "react-redux";

const UserRoute = ({ children, ...rest }) => {
  //   const { user } = useSelector((state) => ({ ...state }));
  const auth = JSON.parse(window.localStorage.getItem("auth"));

  return auth && auth.user ? <Route {...rest} /> : <LoadingToRedirect />;
};

export default UserRoute;
