import React, { useEffect } from "react";
import { Spin, Col, Row } from "antd";
import { useHistory } from "react-router-dom";

const LineLogin2 = () => {
  const liff = window.liff;
  let history = useHistory();
  // get liff id
  const liffId = sessionStorage.getItem("liffId2");

  useEffect(() => {
    liff
      .init({ liffId: liffId })
      .then(async () => {
        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          let lineToken = liff.getAccessToken();
          localStorage.setItem("lineToken2", lineToken);

          // history.push("/lineLoginProd");

          liff.getProfile().then((profile) => {
            localStorage.setItem("lineProfile2", JSON.stringify(profile));
            history.push("/lineLoginProd");
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <div className="backgroundHome">
        <Row justify="space-around" align="middle" className="h-100">
          <Col>
            <Spin size="large" />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LineLogin2;
