// ModalComponent.js
import React, { useState } from "react";
import { Modal, Checkbox, Button } from "antd";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useHistory } from "react-router-dom";
import { Accept_lucky_draw } from "../functions/lucky_draw";

const LuckyTermsAndConditions = ({ visible, onClose, token }) => {
  const [checked, setChecked] = useState(false);
  const history = useHistory();

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  const handleConfirm = () => {
    onClose(); // Close the modal
    Accept_lucky_draw(token, { accepted_campaign: true })
      .then((res) => {
        localStorage.setItem("accepted_campaign", "true");
        history.push("/lucky-draw-detail");
      })
      .catch((err) => {
        console.log("err: ", err);
        alert("บันทึกไม่สำเร็จ");
      });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null} // Disable default footer
    >
      <div
        className="mb-3 resgisterFoodter rules"
        controlId="formBasicCheckbox"
      >
        <b>
          <h4 style={{ textAlign: "center" }}>ข้อกำหนดและเงื่อนไข </h4>
        </b>
        <b>
          <h4 style={{ textAlign: "center" }}>
            &nbsp;&nbsp;เข้าร่วมกิจกรรม &nbsp;
            <span style={{ color: "#FF0099" }}>Lucky Draw</span>
          </h4>
        </b>

        <Row className="align-items-center displaychecklistDetail_home">
          <Col>
            <p class="p1">
              <span class="s1">1. </span>
              <span class="s2">ระยะเวลากิจกรรม</span>{" "}
              <span class="s2">ร่วมสนุกได้ตั้งแต่วันที่</span>{" "}
              <span class="s1">17 </span>
              <span class="s2">พฤษภาคม</span> <span class="s1">2567 </span>
              <span class="s2">ถึง</span> <span class="s1">25 </span>
              <span class="s2">ธันวาคม</span> <span class="s1">2567</span>
            </p>
            <p class="p1">
              <span class="s1">2. </span>
              <span class="s2">
                การสะสมยอดซื้อเพื่อรับคะแนนที่จะนำมาแลกเป็นสิทธิ์ในการแลกลุ้นรางวัล&nbsp;
              </span>
            </p>
            <p class="p1">
              <span class="s1">3. </span>
              <span class="s2">การจับรางวัล</span>{" "}
              <span class="s2">และของรางวัลแบ่งเป็น</span>{" "}
              <span class="s1">3 </span>
              <span class="s2">ครั้ง</span>&nbsp;<span class="s1">&nbsp;</span>
              &nbsp;<span class="s1 Apple-converted-space">&nbsp;</span>
            </p>
            <p class="p1">
              <span class="s2">ครั้งที่</span>{" "}
              <span class="s1">1 : นำคะแนนแลกสิทธิ์ตั้งแต่</span>
              <span class="s2"> 17 </span>
              <span class="s1">พฤษภาคม</span>
              <span class="s2"> 2567 </span>
              <span class="s1">ถึง</span>
              <span class="s2"> 24 </span>
              <span class="s1">มิถุนายน</span>
              <span class="s2"> 2567 จับรางวัล</span>{" "}
              <span class="s2">วันที่</span> <span class="s1">28 </span>
              <span class="s2">มิถุนายน</span> <span class="s1">2567</span>
            </p>
            <p class="p2">
              <span class="s2">&nbsp;&nbsp;&nbsp;- </span>
              <span class="s3">ไดร์เป่าผม</span> <span class="s2">Dyson </span>
              <span class="s3">รุ่น</span> <span class="s2">Supersonic</span>{" "}
              <span class="s4">
                <sup>TM </sup>
              </span>
              <span class="s2">HD15 </span>
              <span class="s3">จำนวน</span> <span class="s2">1 </span>
              <span class="s3">รางวัล</span> <span class="s3">รางวัลละ</span>{" "}
              <span class="s2">18,000 </span>
              <span class="s3">บาท</span>
            </p>
            <p class="p1">
              <span class="s2">ครั้งที่</span>{" "}
              <span class="s1">2: นำคะแนนแลกสิทธิ์ตั้งแต่</span>
              <span class="s2"> 25 </span>
              <span class="s1">มิถุนายน</span>
              <span class="s2"> 2567 </span>
              <span class="s1">ถึง</span>
              <span class="s2"> 23 </span>
              <span class="s1">กันยายน</span>
              <span class="s2"> 2567 จับรางวัล</span>{" "}
              <span class="s2">วันที่</span> <span class="s1">27 </span>
              <span class="s2">กันยายน</span> <span class="s1">2567</span>
            </p>
            <p class="p2">
              <span class="s2">&nbsp;&nbsp;&nbsp;- </span>
              <span class="s3">เครื่องดูดฝุ่น</span>{" "}
              <span class="s2">Dyson </span>
              <span class="s3">รุ่น</span>{" "}
              <span class="s2">V8 Slim Fluffy </span>
              <span class="s3">จำนวน</span> <span class="s2">1 </span>
              <span class="s3">รางวัล</span> <span class="s3">รางวัลละ</span>{" "}
              <span class="s2">16,000 </span>
              <span class="s3">บาท</span>
            </p>
            <p class="p1">
              <span class="s2">ครั้งที่</span>{" "}
              <span class="s1">3: นำคะแนนแลกสิทธิ์ตั้งแต่</span>
              <span class="s2"> </span>
              <span class="s1">ถึง</span>
              <span class="s2"> 24 </span>
              <span class="s1">กันยายน</span>
              <span class="s2"> 2567</span>
              <span class="s1">ถึง</span>
              <span class="s2"> 25 </span>
              <span class="s1">ธันวาคม</span>
              <span class="s2"> 2567 จับรางวัล</span>{" "}
              <span class="s2">วันที่</span> <span class="s1">27 </span>
              <span class="s2">ธันวาคม</span> <span class="s1">2567</span>
            </p>
            <p class="p2">
              <span class="s2 Apple-converted-space">&nbsp;&nbsp; </span>
              <span class="s2">-iPhone 16 Pro Max 512GB</span>{" "}
              <span class="s3">จำนวน</span> <span class="s2">1 </span>
              <span class="s3">รางวัล</span> <span class="s3">รางวัลละ</span>{" "}
              <span class="s2">65,000 </span>
              <span class="s3">บาท</span>
            </p>
            <p class="p1">
              <span class="s1">(</span>{" "}
              <span class="s2">
                ไม่สามารถแลกเป็นเงินสดหรือจ่ายแทนเป็นเงินสดให้กับผู้โชคดีแต่อย่างใด{" "}
              </span>
              <span class="s1">)</span>
            </p>
            <p class="p1">
              <span class="s2">รวมของรางวัลทั้งสิ้น</span>{" "}
              <span class="s1">3 </span>
              <span class="s2">รางวัล</span>{" "}
              <span class="s2">รวมมูลค่าทั้งสิ้น</span>{" "}
              <span class="s1">99,000 </span>
              <span class="s2">บาท</span> <span class="s1">(</span>{" "}
              <span class="s2">เก้าหมื่นเก้าพันบาทถ้วน</span>{" "}
              <span class="s1">)</span>
            </p>
            <p class="p3">&nbsp;</p>
            <p class="p1">
              <span class="s1">4. </span>
              <span class="s2">ทุก</span> <span class="s1">2 </span>
              <span class="s2">คะแนน</span>{" "}
              <span class="s2">สามารถแลกเป็น</span> <span class="s1">1 </span>
              <span class="s2">สิทธิ์เพื่อลุ้นของรางวัลในแต่ละครั้ง</span>&nbsp;
              <span class="s1 Apple-converted-space">&nbsp;</span>
            </p>
            <p class="p1">
              <span class="s1">5. </span>
              <span class="s2">ผู้ที่ได้รับรางวัล</span>{" "}
              <span class="s2">จากการจับรางวัลในครั้งที่</span>{" "}
              <span class="s1">1 </span>
              <span class="s2">จะไม่มีสิทธิ์ลุ้นรางวัล</span>{" "}
              <span class="s2">และ</span> <span class="s1">/</span>{" "}
              <span class="s2">หรือ</span>{" "}
              <span class="s2">รับรางวัลในครั้งที่</span>{" "}
              <span class="s1">2 </span>
              <span class="s2">ได้</span>
            </p>
            <p class="p1">
              <span class="s1">6. </span>
              <span class="s2">ผู้ที่ได้รับรางวัล</span>{" "}
              <span class="s2">จากการจับรางวัลในครั้งที่</span>{" "}
              <span class="s1">1 </span>
              <span class="s2">มีสิทธิ์ลุ้นรางวัลในครั้งที่</span>{" "}
              <span class="s1">3 </span>
              <span class="s2">ได้</span>
            </p>
            <p class="p1">
              <span class="s1">7. </span>
              <span class="s2">ทุก</span> <span class="s2">ๆ</span>{" "}
              <span class="s2">ยอดสินค้า</span> <span class="s1">100 </span>
              <span class="s2">บาท</span> <span class="s1">= 1 </span>
              <span class="s2">คะแนน</span> <span class="s1">(</span>{" "}
              <span class="s2">
                สามารถซื้อสินค้าเพื่อร่วมกิจกรรมสะสมคะแนนได้จากทุกช่องทาง{" "}
              </span>
              <span class="s1">)</span>
            </p>
            <p class="p1">
              <span class="s1">8. </span>
              <span class="s2">
                ทุกคะแนนที่สะสมสามารถใช้แลกของพรีเมียม
              </span>{" "}
              <span class="s1">Moochie </span>
              <span class="s2">ได้ตามที่แบรนด์กำหนด</span>
            </p>
            <p class="p3">&nbsp;</p>
            <p class="p1">
              <span class="s2">
                <strong>วิธีการร่วมกิจกรรม</strong>&nbsp;
              </span>
            </p>
            <p class="p1">
              <span class="s1">1. </span>
              <span class="s2">สะสมยอดซื้อจากใบเสร็จทุกช่องทาง</span>{" "}
              <span class="s1">(Online shop &amp; Pet shop </span>
              <span class="s2">หรือ</span> <span class="s2">งาน</span>{" "}
              <span class="s1">Event </span>
              <span class="s2">ของ</span> <span class="s1">Moochie </span>
              <span class="s2">ตลอดแคมเปญ</span> <span class="s1">)</span>
            </p>
            <p class="p1">
              <span class="s1">2. </span>
              <span class="s2">อัปโหลดภาพใบเสร็จผ่านระบบ</span>{" "}
              <span class="s2">และรอ</span>{" "}
              <span class="s1">Admin approve </span>
              <span class="s2">ยอดใบเสร็จ</span> <span class="s1">(</span>{" "}
              <span class="s2">ตัดยอดใบเสร็จทุก</span>{" "}
              <span class="s1">18.00 </span>
              <span class="s2">น</span> <span class="s1">. </span>
              <span class="s2">ของทุกวัน</span> <span class="s1">)</span>
            </p>
            <p class="p1">
              <span class="s1">3. </span>
              <span class="s2">ทุกๆ</span> <span class="s2">ยอดซื้อ</span>{" "}
              <span class="s1">100 </span>
              <span class="s2">บาท</span> <span class="s2">รับ</span>{" "}
              <span class="s1">1 </span>
              <span class="s2">คะแนน</span>
            </p>
            <p class="p1">
              <span class="s1">4. </span>
              <span class="s2">คะแนนสะสมจากยอดซื้อใบเสร็จ</span>{" "}
              <span class="s2">จะแสดงผลในวันถัดไป</span>
            </p>
            <p class="p1">
              <span class="s1">5. </span>
              <span class="s2">
                ใช้คะแนนเพื่อแลกเป็นสิทธิ์เพื่อใช้ในการลุ้นรางวัลทั้ง{" "}
              </span>
              <span class="s1">3 </span>
              <span class="s2">ครั้ง</span> <span class="s1">(2 </span>
              <span class="s2">คะแนน</span> <span class="s2">เท่ากับ</span>{" "}
              <span class="s1">1 </span>
              <span class="s2">สิทธิ์</span> <span class="s1">)</span>
            </p>
            <p class="p2">
              <span class="s2">5. </span>
              <span class="s3">สามารถร่วมสนุกได้โดย</span>{" "}
              <span class="s3">ลงทะเบียนผ่าน</span>{" "}
              <span class="s2">LINE OFFICIAL MOOCHIE: </span>
              <a href="https://lin.ee/Zmsq0hv">
                <span class="s2 s6">https://lin.ee/Zmsq0hv</span>{" "}
              </a>
              <span class="s3">เท่านั้น</span>
            </p>
            <p class="p1">
              <span class="s1">6. </span>
              <span class="s2">
                ผู้ร่วมกิจกรรมต้องกรอกข้อมูลตามที่ระบบกำหนดให้ครบถ้วน
                เพื่อรับสิทธิ์ตามที่แบรนด์กำหนด
              </span>
            </p>
            <p class="p3">&nbsp;</p>
            <p class="p1">
              <span class="s2">
                <strong>กำหนดการประกาศรางวัล</strong>&nbsp;
              </span>
            </p>
            <p class="p1">
              <span class="s2">
                กำหนดประกาศผู้ได้รับรางวัลจากการลุ้นรางวัลในแต่ละครั้ง&nbsp;
              </span>
              <span class="s1 Apple-converted-space">&nbsp;</span>
            </p>
            <p class="p1">
              <span class="s2">ครั้งที่</span> <span class="s1">1 : </span>
              <span class="s2">จับรางวัล</span> <span class="s2">วันที่</span>{" "}
              <span class="s1">28 </span>
              <span class="s2">มิถุนายน</span> <span class="s1">2567</span>
            </p>
            <p class="p1">
              <span class="s2">ครั้งที่</span> <span class="s1">2: </span>
              <span class="s2">จับรางวัล</span> <span class="s2">วันที่</span>{" "}
              <span class="s1">27 </span>
              <span class="s2">กันยายน</span> <span class="s1">2567</span>
            </p>
            <p class="p1">
              <span class="s2">ครั้งที่</span> <span class="s1">3: </span>
              <span class="s2">จับรางวัล</span> <span class="s2">วันที่</span>{" "}
              <span class="s1">27 </span>
              <span class="s2">ธันวาคม</span> <span class="s1">2567</span>
            </p>
            <p class="p1">
              <br />
              <span class="s2">ภายในเวลา</span> <span class="s1">18.00 </span>
              <span class="s2">น</span> <span class="s1">. (</span>{" "}
              <span class="s2">วัน</span>{" "}
              <span class="s2">
                และเวลาอาจมีการเปลี่ยนแปลงตามที่บริษัทกำหนด{" "}
              </span>
              <span class="s1">) </span>
              <span class="s2">ทางเว็บไซต์กิจกรรม</span>{" "}
              <span class="s2">โดยสามารถเข้าผ่าน</span>{" "}
              <span class="s1">
                {" "}
                LINE OFFICIAL MOOCHIE: https://lin.ee/Zmsq0hv&nbsp;
              </span>
            </p>
            <p class="p3">&nbsp;</p>
            <p class="p1">
              <span class="s2">
                <strong>วิธีการประกาศรางวัล</strong>&nbsp;
              </span>
            </p>
            <p class="p1">
              <span class="s2">บริษัทฯ</span>{" "}
              <span class="s2">
                จะประกาศผู้ได้รับรางวัลจากการลุ้นรางวัลในแต่ละครั้ง&nbsp;
              </span>
              <span class="s1 Apple-converted-space">&nbsp;</span>
            </p>
            <p class="p1">
              <span class="s2">ครั้งที่</span> <span class="s1">1 : </span>
              <span class="s2">จับรางวัล</span> <span class="s2">วันที่</span>{" "}
              <span class="s1">28 </span>
              <span class="s2">มิถุนายน</span> <span class="s1">2567</span>
            </p>
            <p class="p1">
              <span class="s2">ครั้งที่</span> <span class="s1">2: </span>
              <span class="s2">จับรางวัล</span> <span class="s2">วันที่</span>{" "}
              <span class="s1">27 </span>
              <span class="s2">กันยายน</span> <span class="s1">2567</span>
            </p>
            <p class="p1">
              <span class="s2">ครั้งที่</span> <span class="s1">3: </span>
              <span class="s2">จับรางวัล</span> <span class="s2">วันที่</span>{" "}
              <span class="s1">27 </span>
              <span class="s2">ธันวาคม</span> <span class="s1">2567</span>
            </p>
            <p class="p2">
              <span class="s3">ทางเว็บไซต์กิจกรรม</span>{" "}
              <span class="s3">โดยสามารถเข้าผ่าน</span>{" "}
              <span class="s2">
                {" "}
                LINE OFFICIAL MOOCHIE: https://lin.ee/Zmsq0hv&nbsp;
              </span>
            </p>
            <p class="p3">&nbsp;</p>
            <p class="p1">
              <span class="s2">
                <strong>วิธีการร่วมกิจกรรม</strong>&nbsp;
              </span>
            </p>
            <p class="p1">
              <span class="s1">1. </span>
              <span class="s2">กิจกรรมเริ่มตั้งแต่วันที่</span>{" "}
              <span class="s1">17 </span>
              <span class="s2">พฤษภาคม</span> <span class="s1">2567 </span>
              <span class="s2">ถึง</span> <span class="s1">25 </span>
              <span class="s2">ธันวาคม</span> <span class="s1">2567</span>
            </p>
            <p class="p1">
              <span class="s1">2. </span>
              <span class="s2">
                สิ่งของรางวัลไม่สามารถนำไปแลกเป็นเงินสดหรือสิ่งของอื่นใดได้
                ไม่สามารถโอนให้ผู้อื่นได้
              </span>{" "}
              <span class="s2">และไม่มีการจ่ายเงินเป็นส่วนประกอบ</span>
            </p>
            <p class="p1">
              <span class="s1">3. </span>
              <span class="s2">
                การจับรางวัลจะประกาศผู้ได้รับรางวัลจากการลุ้นรางวัลในแต่ละครั้ง&nbsp;
              </span>
            </p>
            <p class="p1">
              <span class="s1">4. </span>
              <span class="s2">
                ผู้โชคดีที่ได้รับรางวัลทั้งที่มีภูมิลำเนาในกรุงเทพมหานคร
                และต่างจังหวัดต้องนำหลักฐานบัตรประจำตัวประชาชน พร้อมสำเนา
              </span>{" "}
              <span class="s1">1 </span>
              <span class="s2">ชุด</span>{" "}
              <span class="s2">
                มาเป็นหลักฐานในการขอรับรางวัลด้วยตนเองที่บริษัท ไทยออซัม
              </span>{" "}
              <span class="s2">จำกัด</span> <span class="s1">(</span>{" "}
              <span class="s2">มหาชน</span> <span class="s1">) </span>
              <span class="s2">สำนักงานแห่งใหญ่ตั้งอยู่เลขที่</span>{" "}
              <span class="s1">108/126 </span>
              <span class="s2">ซอยแจ้งวัฒนะ</span> <span class="s1">20 </span>
              <span class="s2">ถนนแจ้งวัฒนะ</span>{" "}
              <span class="s2">ตำบลปากเกร็ด</span>{" "}
              <span class="s2">อำเภอปากเกร็ด</span>{" "}
              <span class="s2">จังหวัดนนทบุรี</span>{" "}
              <span class="s2">ในวันและเวลาทำการภายในระยะ</span>{" "}
              <span class="s1">7 </span>
              <span class="s2">วันนับแต่วันที่ประกาศรายชื่อผู้โชคดี</span>{" "}
              <span class="s2">หากเลยกำหนดถือว่าสละสิทธิ์</span>{" "}
              <span class="s2">และบริษัทฯ</span>{" "}
              <span class="s2">
                จะมอบของรางวัลดังกล่าวให้กับองค์กรสาธารณกุศลต่อไป&nbsp;
              </span>
            </p>
            <p class="p1">
              <span class="s1">5. </span>
              <span class="s2">บริษัทฯ</span>{" "}
              <span class="s2">
                มีสิทธิ์คัดเลือกรายชื่อผู้ได้รับรางวัลสำรองไว้
                สำหรับกรณีที่ผู้มีสิทธิ์ได้รับรางวัล
              </span>{" "}
              <span class="s2">และ</span> <span class="s1">/</span>{" "}
              <span class="s2">หรือ</span>{" "}
              <span class="s2">
                ผู้ได้รับรางวัลต้องถูกตัดสิทธิ์จากการรับรางวัลเนื่องจากมีคุณสมบัติไม่ครบถ้วนหรือไม่ปฏิบัติตามกติกาและเงื่อนไขของการจับรางวัลที่กำหนดไว้&nbsp;
              </span>
            </p>
            <p class="p1">
              <span class="s1">6. </span>
              <span class="s2">ผู้โชคดีที่ได้รับรางวัลมูลค่า</span>{" "}
              <span class="s1">1,000 </span>
              <span class="s2">บาทขึ้นไป</span>{" "}
              <span class="s2">จะต้องหักภาษี</span> <span class="s2">ณ</span>{" "}
              <span class="s2">ที่จ่าย</span>{" "}
              <span class="s2">ของมูลค่ารางวัล</span>{" "}
              <span class="s2">ตามคำสั่ง</span>{" "}
              <span class="s2">กรมสรรพากร</span> <span class="s2">ที่</span>{" "}
              <span class="s2">ท</span> <span class="s1">.</span>{" "}
              <span class="s2">ป</span> <span class="s1">. 4/2528 </span>
              <span class="s2">ประกอบคำสั่งกรมสรรพากรที่</span>{" "}
              <span class="s2">ท</span> <span class="s1">.</span>{" "}
              <span class="s2">ป</span> <span class="s1">. 104/2544 </span>
              <span class="s2">และ</span>{" "}
              <span class="s2">ค่าธรรมเนียมและค่าภาษีอื่น</span>{" "}
              <span class="s2">ๆ</span>{" "}
              <span class="s2">
                ที่เกิดขึ้นนอกเหนือจากมูลค่าสิ่งของรางวัลที่ได้รับ
                ผู้โชคดีจะต้องเป็นผู้รับผิดชอบค่าใช้จ่ายเอง&nbsp;
              </span>
            </p>
            <p class="p1">
              <span class="s1">7. </span>
              <span class="s2">ค่าธรรมเนียม</span>{" "}
              <span class="s2">ค่าภาษีอื่น</span>{" "}
              <span class="s2">ค่าใช้จ่ายอื่น</span> <span class="s2">และ</span>{" "}
              <span class="s2">ส่วนเกินมูลค่าสินค้า</span>{" "}
              <span class="s2">
                ที่เกิดขึ้นนอกเหนือจากมูลค่าสิ่งของรางวัลที่ได้รับ
                ผู้โชคดีจะต้องเป็นผู้รับผิดชอบค่าใช้จ่ายเอง สอบถามข้อมูล
              </span>{" "}
              <span class="s2">และตรวจสอบเงื่อนไขเพิ่มเติมได้ที่</span>{" "}
              <span class="s1">.</span> <span class="s2">บริษัท</span>{" "}
              <span class="s2">ไทยออซัม</span> <span class="s2">จำกัด</span>{" "}
              <span class="s1">(</span> <span class="s2">มหาชน</span>{" "}
              <span class="s1">) 02-8215545</span>
            </p>
            <p class="p1">
              <span class="s1">8. </span>
              <span class="s2">บริษัทฯ</span>{" "}
              <span class="s2">
                เป็นเพียงผู้จัดหาของรางวัลเพื่อรายการชิงโชคนี้เท่านั้น
                โดยบริษัทฯ
              </span>{" "}
              <span class="s2">มิได้มีส่วนเกี่ยวข้องกับการผลิต</span>{" "}
              <span class="s2">และ</span> <span class="s1">/</span>{" "}
              <span class="s2">หรือการจำหน่าย</span> <span class="s2">และ</span>{" "}
              <span class="s1">/</span> <span class="s2">หรือ</span>{" "}
              <span class="s2">การให้บริการ</span>{" "}
              <span class="s2">ในของรางวัลที่ระบุไว้แต่อย่างใด</span>{" "}
              <span class="s2">ดังนั้น</span> <span class="s2">บริษัทฯ</span>{" "}
              <span class="s2">จึงไม่ต้องรับผิดชอบในความเสียหาย</span>{" "}
              <span class="s2">บุบสลาย</span>{" "}
              <span class="s2">ชำรุดบกพร่อง</span> <span class="s2">และ</span>{" "}
              <span class="s1">/</span> <span class="s2">หรือ</span>{" "}
              <span class="s2">
                ในเหตุอื่นใดอันเกิดขึ้นกับของรางวัลทั้งสิ้น&nbsp;
              </span>
            </p>
            <p class="p1">
              <span class="s1">9. </span>
              <span class="s2">พนักงานบริษัท</span>{" "}
              <span class="s2">ไทยออซัม</span> <span class="s2">จำกัด</span>{" "}
              <span class="s1">(</span> <span class="s2">มหาชน</span>{" "}
              <span class="s1">) , </span>
              <span class="s2">บริษัทตัวแทนโฆษณาที่เกี่ยวข้อง</span>{" "}
              <span class="s2">คณะกรรมการดำเนินรายการและครอบครัว</span>{" "}
              <span class="s2">ไม่มีสิทธิ์เข้าร่วมชิงรางวัลในรายการ</span>
            </p>
            <p class="p1">
              <span class="s1">10. </span>
              <span class="s2">
                สำหรับกิจรรมสะสมคะแนนเพื่อลุ้นรับรางวัล
              </span>{" "}
              <span class="s2">ทางทีมจะตรวจสอบข้อมูลเมื่อครบถ้วน</span>{" "}
              <span class="s2">และถูกต้องแล้ว</span>{" "}
              <span class="s2">
                จะดำเนินการทยอยจัดส่งของรางวัลไปตามที่อยู่ที่ได้ลงทะเบียน
                โดยการจัดส่งของรางวัลจะจัดส่งเป็นรอบๆทุก
              </span>{" "}
              <span class="s1">7 </span>
              <span class="s2">วัน</span>{" "}
              <span class="s2">ตลอดจนหมดระยะเวลาของกิจกรรม</span>
            </p>
          </Col>
        </Row>

        <br></br>
      </div>
      <Checkbox checked={checked} onChange={handleCheckboxChange}>
        ฉันได้อ่านและยอมรับข้อตกลงและเงื่อนไข
      </Checkbox>
      <div style={{ marginTop: "16px", textAlign: "center" }}>
        <Button type="primary" onClick={handleConfirm} disabled={!checked}>
          ยอมรับ
        </Button>
      </div>
    </Modal>
  );
};

export default LuckyTermsAndConditions;
