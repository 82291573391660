import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";

const AlertSuscess = ({ match }) => {
  const [text_,setText]=useState("")
  const [type, setType] = useState("");

  useEffect(() => {
    if(match.params.page === "Upload_receipt"){
      setText("ยอดซื้อจะถูกเพิ่มในวันถัดไป")
    } else if(match.params.page === "lucky-draw-exchange"){
      // setText("จับรางวัลครั้งที่ 1: 28 มิถุนายน 2567")
      
    }

    setType(match.params.page);
  }, [match, text_]);
  const handlePage = (e) => {
    if (
      match.params.page === "add_address" ||
      match.params.page === "add_pats" ||
      match.params.page === "edit_profile" ||
      match.params.page === "edit_address"     
    ) {
      window.location.href = "/edit_profile";
    } else if (match.params.page === "Upload_receipt") {
      window.location.href = "/lucky-draw-exchange";
    } else if (match.params.page === "add_addressRedeem") {
      const product_id_Input = localStorage.getItem("product_id_Storage");
      const point_input = localStorage.getItem("Point_Storage");
      window.location.href = `/delivery/${product_id_Input}/${point_input}`;
    } else if (match.params.page === "lucky-draw-exchange") {
      window.location.href = "/lucky-draw-detail";
    }
  };

  return (
    <center>
      <div
        className="backgroundMain"
        style={{ paddingTop: "40%", height: "900px" }}
      >
        <div className="success"></div>
        <br></br>
        <b className="Header_regis_pets " style={{ fontSize: "30px" }}>
          {" "}
          บันทึกสำเร็จ
        </b>
        <br></br>
        <b className="Header_regis_pets " style={{ fontSize: "16px" }}>
          {text_}
          {
            type === "Upload_receipt" && (
              <>
                <br />
                ใช้คะแนนของคุณ แลกลุ้นรางวัลได้​
              </>
            )
          }
        </b>
        <br></br>
        <br></br>

        <Button
          style={{
            backgroundColor: "#FF0099",
            borderColor: "#FF0099",
            // width: "90px",
          }}
          variant="primary"
          type="submit"
          onClick={handlePage}
          className="px-4"
        >
          {
            type === "Upload_receipt" ? "แลกคะแนน" : "กลับ"
          }
        </Button>
      </div>
    </center>
  );
};

export default AlertSuscess;
