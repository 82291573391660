import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import UploadImage from "../components/upload_image";
import { addPet } from "../functions/pets";
import { LoadingOutlined } from "@ant-design/icons";
import { uploadImage } from "../functions/uploadImg";
import { useSelector } from "react-redux";

const Register_pets = () => {
  const { user } = JSON.parse(window.localStorage.getItem("auth"))
  const token = user.token;

  const [img, setImg] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [age, setAge] = useState(0);
  const [breed, setBreed] = useState("");
  const [sex, setSex] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [loading, setLoading] = useState(false);
  const maxDate = new Date().toISOString().split("T")[0];

  const handleYearPats = (e) => {
    // const newDate = setValue(moment(new Date(e.target.value)).format("YYYY-MM-DD"));
    // setValue(newDate);
    // console.log(newDate);
    setBirthdate(e.target.value);

    const currentDate = new Date();
    const birthDate = new Date(e.target.value);
    const ageDiffMs = currentDate - birthDate;
    const ageDate = new Date(ageDiffMs);
    const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    if (calculatedAge === 0) {
      setAge(1);
    } else {
      setAge(calculatedAge);
    }
    // loadAgepets();
  };

  const handleSubmit = async (e) => {
    if (img === "") {
      alert("กรุณาอัพโหลดรูปภาพ");
    }
    e.preventDefault();
    setLoading(true);
    let data = new FormData();
    data.append("file", img);
    data.append("upload_preset", process.env.REACT_APP_PRESET_NAME);
    data.append("cloud_name", process.env.REACT_APP_CLOUD_NAME);
    data.append("folder", "uat-moochie");

    let authtoken = token;

    try {
      const resp = await uploadImage(data);
      
      let addPets = {
        img: resp.data.secure_url,
        name: name,
        type: type,
        age: age,
        breed: breed,
        sex: sex,
        birthdate: birthdate,
      };

      addPet(authtoken, addPets)
        .then((res) => {
          setLoading(false);
          const page = "add_pats";
          localStorage.setItem("petStatus", "1");
          window.location.href = `alertSuscess/${page}`;
        })
        .catch((err) => {
          setLoading(false);
          console.log("err: ", err);
          alert("บันทึกไม่สำเร็จ");
        });
    } catch (error) {
      setLoading(false);
      console.log("ERROR :", error);
    }
  };

  return (
    <div className="backgroundRegister_pets">
      <br></br>

      <Button
        className="botton_back"
        style={{ backgroundColor: "#FFF", borderColor: "#FFF" }}
        variant="light"
        href="edit_profile"
      >
        <b className="font_ButtonRegi_pets"> </b>{" "}
      </Button>
      <b className="Header_regis_pets "> ลงทะเบียนสัตว์เลี้ยง</b>

      <br></br>
      <center>
        <Form.Label>อัพโหลดภาพสัตว์เลี้ยง</Form.Label>
      </center>
      <div className="image_upload">
        <UploadImage img={img} setImg={setImg} required />
      </div>
      <br></br>

      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3 ">
          <Form.Control
            placeholder="ชื่อ"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3 ">
          <Form.Select
            aria-label="Default select example"
            value={type}
            onChange={(e) => setType(e.target.value)}
            required
          >
            <option>ชนิด</option>
            <option value="dog">สุนัข</option>
            <option value="cat">แมว</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3 ">
          <Form.Control
            placeholder="สายพันธุ์สัตว์เลี้ยง"
            value={breed}
            onChange={(e) => setBreed(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3 ">
          <Form.Label>วันเดือนปีเกิด</Form.Label>
          <input
            type="date"
            className="form-control"
            value={birthdate}
            onChange={handleYearPats}
            // onChange={(e)=>handleYearPats(e)}
            // onChange={(e) => setBirthdate(e.target.value)}
            max={maxDate}
            placeholder="วันเดือนปี"
            required
          ></input>
          <br></br>
        </Form.Group>
        <Form.Group className="mb-3 ">
          <Form.Label>อายุ (ปี)</Form.Label>
          <Form.Control
            placeholder="อายุ"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            disabled
          />
          <Form.Label style={{ color: "#696969" }}>
            * หากอายุน้อยกว่า 1 ปีจะเท่ากับ 1 ปี
          </Form.Label>
        </Form.Group>

        <br></br>
        <div className="center">
          <table>
            <tr>
              <input
                type="radio"
                name="Product1"
                value="female"
                onChange={(e) => setSex(e.target.value)}
              />
              &nbsp;&nbsp;เพศเมีย
            </tr>
            <tr>
              <input
                type="radio"
                name="Product1"
                value="male"
                onChange={(e) => setSex(e.target.value)}
              />
              &nbsp;&nbsp;เพศผู้
            </tr>
          </table>
        </div>

        <br></br>
        <center>
          <Form.Group controlId="formBasicCheckbox">
            {loading ? (
              <LoadingOutlined className="text-danger" />
            ) : (
              <Button
                style={{
                  backgroundColor: "#FF0099",
                  borderColor: "#FF0099",
                  width: "90px",
                }}
                variant="primary"
                type="submit"
                loadings
              >
                บันทึก{" "}
              </Button>
            )}
          </Form.Group>
        </center>
      </Form>
    </div>
  );
};

export default Register_pets;
