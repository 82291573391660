import axios from "axios";

//จังหวัด
export const getProvince = async (authtoken) => {
    return await axios.get(`${process.env.REACT_APP_API}/get-province`, {
      headers: {
        session: authtoken,
      },
      
    });
  };

  //อำเภอ
  export const getDistricts = async (authtoken,province_id) => {
    return await axios.get(`${process.env.REACT_APP_API}/get-districts`, {
      headers: {
        session: authtoken,
      },
      params:{
        id:province_id,
      },
      
    });
  };

  //ตำบล
  export const getSubdistricts = async (authtoken,district_id) => {
    return await axios.get(`${process.env.REACT_APP_API}/get-subdistricts`, {
      headers: {
        session: authtoken,
      },
      params:{
        id:district_id,
      },
      
    });
  };

  
  export const getZipcode = async (authtoken,subDistrict_id) => {
    return await axios.get(`${process.env.REACT_APP_API}/get-zipcode`, {
      headers: {
        session: authtoken,
      },
      params:{
        id:subDistrict_id,
      },
      
    });
  };

  //ร้านในจังหวัด
  export const getShopProvinces = async (authtoken,id) => {
    return await axios.get(`${process.env.REACT_APP_API}/get-shop`, {
      headers: {
        session: authtoken,
      },
      params:{
        id:id,
      },
      
    });
  };