import axios from "axios";

    export const addPet = async (authtoken,addPets) =>
    await axios.post(`${process.env.REACT_APP_API}/add-pet`,addPets, {
      headers: {
        session: authtoken,
      },
    });



    export const getPet = async (authtoken,lineToken) => {
      return await axios.get(`${process.env.REACT_APP_API}/get-pet`, {
        headers: {
          session: authtoken,
          Authorization:lineToken,
        },
        
      });
    };

    export const getPetID = async (authtoken,petID) => {
      return await axios.get(`${process.env.REACT_APP_API}/get-pet-id`, {
        headers: {
          session: authtoken,
        },
        params:{
          id:petID,
        }
        
      });
    };

    export const updatePet = async (authtoken,updatePet_) =>
    await axios.patch(`${process.env.REACT_APP_API}/edit-pet`,updatePet_, {
      headers: {
        session: authtoken,
      },
     
    });

    export const removePet = async (PetID,authtoken) => {
      return await axios.delete(`${process.env.REACT_APP_API}/del-pet`, {
        headers: {
          session: authtoken,
        },
        data:{
          id:PetID,
        }
      });
    };

    export const uploadImage = async (data) =>
    await axios.post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`,data);