import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import Menu from "../components/menu";
import Banner_KV from "../components/banner_KV";
import { User_info } from "../functions/lucky_draw";
import { Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import LuckyRewardTerm from "../components/lucky_reward_term";
import LuckyLawsTerm from "../components/lucky_laws_term";
import Carousel from "react-bootstrap/Carousel";
const { confirm } = Modal;

const LuckyDrawDetail = ({ history }) => {
  // const lineToken = localStorage.getItem("lineAccessToken");

  const addressStatus = localStorage.getItem("addressStatus");
  const petStatus = localStorage.getItem("petStatus");
  const profireStatus = localStorage.getItem("member_status");
  const [token, setToken] = useState("");
  // const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MywibWVtYmVyX2lkIjoxLCJjcmVhdGVkX2F0IjoiMjAyNC0wMy0yOCAxNzowOToyMCJ9.Y9O8oN0khCWBqCYQ0nZzkXO6MOwrW4V3kD24SG2gwUw";
  const [modalRewardVisible, setModalRewardVisible] = useState(false);
  const [modalLawsVisible, setModalLawsVisible] = useState(false);
  const [point, setPoint] = useState("");
  const [ticket, setTicket] = useState("");

  const handleOpenRewardModal = () => {
    setModalRewardVisible(true);
  };

  const handleCloseRewardModal = () => {
    setModalRewardVisible(false);
  };

  const handleOpenLawsModal = () => {
    setModalLawsVisible(true);
  };

  const handleCloseLawsModal = () => {
    setModalLawsVisible(false);
  };

  useEffect(() => {
    // window.location.href = "home";
    // return;

    if (addressStatus === "0" || petStatus === "0" || profireStatus === "0") {
      showPromiseConfirm();
    }

    const { user } = JSON.parse(window.localStorage.getItem("auth"));
    setToken(user.token);
    loadUserInfo(user.token);
  }, []);

  const loadUserInfo = async (authToken) => {
    User_info(authToken).then((res) => {
      console.log("res.data", res.data.data);
      if (res.data) {
        setPoint(res.data.data.points);
        setTicket(res.data.data.credit);
      }
    });
  };

  const showPromiseConfirm = () => {
    let msgTitle = " ";
    let msgDesc = " ";
    let path = " ";

    // console.log("profireStatus",profireStatus)
    // console.log("addressStatus",addressStatus)
    // console.log("petStatus",petStatus)

    if (profireStatus === "0") {
      msgTitle = "กรุณาเพิ่มข้อมูลส่วนตัวให้ครบถ้วน";
      msgDesc = "กรุณาเพิ่มข้อมูลให้ครบถ้วน";
      path = "/edit_profile";
    } else if (addressStatus === "0") {
      msgTitle = "เพื่อรับสิทธิประโยชน์จากทางแบรนด์ตลอดทั้งปี";
      msgDesc = "กรุณาเพิ่มข้อมูลให้ครบถ้วน";
      path = "/add_address";
    } else if (petStatus === "0") {
      msgTitle = "กรุณาเพิ่ม ID Card ของสัตว์เลี้ยงให้ครบถ้วน";
      msgDesc = "กรุณาเพิ่ม ID Card ของสัตว์เลี้ยงให้ครบถ้วน";
      path = "/register_pets";
    }

    confirm({
      title: msgTitle,
      icon: <InfoCircleOutlined />,
      content: msgDesc,
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          history.push(path);
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  const checkPermission2Exchange = () => {
    // history.push("/lucky-draw-exchange");
    if (addressStatus === "1" && petStatus === "1" && profireStatus === "1") {
      history.push("/lucky-draw-exchange");
    } else {
      showPromiseConfirm();
    }
  };

  return (
    <div>
      <div className="backgroundLuckyDrawDetail">
        <br></br>
        <center>
          <div className="d-flex align-items-center home_cam">
            <div
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
              className="clover_icon"
            ></div>
            <b
              className="Header_LuckyDraw"
              style={{
                fontSize: "2.5em",
              }}
            >
              แลกแต้มลุ้นรางวัล
            </b>
            <div
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
              className="clover_icon"
            ></div>
          </div>
        </center>
        <br></br>

        <Form>
          <Carousel
            className="box_ฺBanner"
            activeIndex={0}
            onSelect={() => {
              console.log("onSelect");
            }}
          >
            <Carousel.Item>
              <img
                className="d-block w-100 borderradius20px"
                src="assets/luckyDraw/MooChie_Lucky-draw-2-9-7-2024.png"
                alt="p1.ll"
              />
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          <div className="lucky_current_point">
            <div style={{ color: "#ff3a89", textAlign: "center" }}>
              คะแนนของฉัน
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/8893f97892e08b36b33b80d486605821e51076038917fa9baa1edaed4eccae92?apiKey=e95df7cd88704225a9c4058d8e5f379d&"
                style={{ marginRight: "15px" }}
              />
              <span style={{ marginRight: "15px", transform: "scale(1.5)" }}>
                {point}{" "}
              </span>
              <span>คะแนน</span>
            </div>
          </div>
          <br></br>
          <br></br>

          <div>
            <center>
              <Col>
                <Button
                  className="exchange_lucky_draw"
                  onClick={() => checkPermission2Exchange()}
                >
                  <b className="font_ButtonUpload">ใช้คะแนนแลกสิทธิ์</b>
                </Button>
              </Col>
            </center>
          </div>

          <div className="lucky_current_draw">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: "white",
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                แลกสิทธิ์แล้ว
              </div>
              <div style={{ cursor: "pointer" }}>
                <img
                  onClick={handleOpenRewardModal}
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/0dc946c1355263cefacfddd96d072c23d488c78093e7ec464646ec8a48f12052?apiKey=e95df7cd88704225a9c4058d8e5f379d&"
                />
                <img
                  onClick={handleOpenLawsModal}
                  loading="lazy"
                  src="/assets/luckyDraw/info-button.png"
                  style={{
                    marginRight: "10px",
                    marginLeft: "10px",
                    width: "20px",
                  }}
                />
              </div>
            </div>
            <div className="lucky_current_draw_detail">
              <span style={{ marginRight: "15px", transform: "scale(1.5)" }}>
                {ticket}{" "}
              </span>
              <span>สิทธิ์</span>
            </div>
          </div>
          {/* LuckyRewardTerm modal */}
          <LuckyRewardTerm
            visible={modalRewardVisible}
            onClose={handleCloseRewardModal}
          />
          {/* LuckyLawsTerm modal */}
          <LuckyLawsTerm
            visible={modalLawsVisible}
            onClose={handleCloseLawsModal}
          />
          <br></br>

          <br></br>
        </Form>
        <br></br>
        <br></br>
        <br></br>
      </div>

      <Menu />
    </div>
  );
};

export default LuckyDrawDetail;
